import React, { useContext } from "react";
import { LandingPageContext } from "~/pages/LandingPage/LandingPageContext";

function Introduction() {
  const { formRef } = useContext(LandingPageContext);
  function moveSmoothly() {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <div className="introduction-container">
      <div className="introduction" />
      <div className="introduction-button">
        <button onClick={moveSmoothly}>
          PREENCHA O FORMULÁRIO ABAIXO E PARTICIPE
        </button>
      </div>
    </div>
  );
}

export default Introduction;
