import React from "react";

export function IconCheck({ color = "#454f63", width = "28", height = "28" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="20 6 9 17 4 12" />
    </svg>
  );
}

export function IconClose({ color = "#2a2e43", width = "24", height = "24" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
  );
}


export const CameraIcon = ({color = "#000000"}) => {
  return (
    <svg
      width="20px"
      height="17px"
      viewBox="0 0 20 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>video_camera_round [#964]</title>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Dribbble-Light-Preview"
          transform="translate(-300.000000, -3921.000000)"
          fill={color}
        >
          <g id="icons" transform="translate(56.000000, 160.000000)">
            <path
              d="M262,3771.3123 L262,3771.36176 L262,3773.76958 L259.767,3772.33333 L262,3771.10006 L262,3771.3123 Z M256,3774.90909 C256,3775.47782 255.552,3775.93939 255,3775.93939 L247,3775.93939 C246.448,3775.93939 246,3775.47782 246,3774.90909 L246,3770.78788 C246,3770.21915 246.448,3769.75758 247,3769.75758 L255,3769.75758 C255.552,3769.75758 256,3770.21915 256,3770.78788 L256,3774.90909 Z M248.11,3765.12121 C248.662,3765.12121 249.11,3765.58382 249.11,3766.15152 C249.11,3766.71921 248.662,3767.18182 248.11,3767.18182 C247.559,3767.18182 247.11,3766.71921 247.11,3766.15152 C247.11,3765.58382 247.559,3765.12121 248.11,3765.12121 L248.11,3765.12121 Z M253.11,3763.06061 C254.213,3763.06061 255.11,3763.98479 255.11,3765.12121 C255.11,3766.25764 254.213,3767.18182 253.11,3767.18182 C252.008,3767.18182 251.11,3766.25764 251.11,3765.12121 C251.11,3763.98479 252.008,3763.06061 253.11,3763.06061 L253.11,3763.06061 Z M262.47,3768.16576 L258,3771.0403 L258,3769.24242 C258,3768.23479 257.38,3767.39818 256.452,3767.21994 C256.809,3766.60382 257.069,3765.89085 257.069,3765.12121 C257.069,3762.84527 255.299,3761 253.09,3761 C251.473,3761 250.099,3761.99321 249.468,3763.41503 C249.056,3763.19661 248.601,3763.06061 248.105,3763.06061 C246.448,3763.06061 245.108,3764.4443 245.108,3766.15152 C245.108,3766.58218 245.112,3766.99224 245.267,3767.36418 C244.57,3767.68667 244,3768.40376 244,3769.24242 L244,3775.42424 C244,3776.5617 245.062,3778 246.167,3778 L256.167,3778 C257.271,3778 258,3776.5617 258,3775.42424 L258,3773.62636 L262.47,3776.50091 C263.137,3776.92952 264,3776.436 264,3775.62721 L264,3769.03945 C264,3768.23067 263.137,3767.73715 262.47,3768.16576 L262.47,3768.16576 Z"
              id="video_camera_round-[#964]"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
