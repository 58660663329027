import React from "react";
import Linkify from "react-linkify";
import { SRLWrapper } from "simple-react-lightbox";
import lightboxOptions from '~/configs/lightbox'

import "./Checkbox.scss";

export default ({ recording, confirmed, label, description, children, attachment, style, ...props }) => {
  let styles = {}
  if (props.disabled) {
    styles = {
      ...styles,
      cursor: "not-allowed"
    }
  }
  if (props.marginBottom) {
    styles = {
      ...styles,
      marginBottom: props.marginBottom + "px"
    }
  }

  function componentHref(href, text, key) {
    return (
        <a href={href} key={key} target="_blank">
            {text}
        </a>
    );
}

  return (
    <label
      className="label-checkbox"
      style={{...styles, ...style}}
    >
      <div>
        <input {...props} className="check-box-primary ">
          {children}
        </input>
        <span className="label-text" />
      </div>

      <div className={`label-description ${confirmed ? "confirmed" : recording ? "recording" : ""}`}>
        <p>
          {attachment ? (
            <span>
              <SRLWrapper options={lightboxOptions}>
                <img src={attachment} alt="" />
              </SRLWrapper>
              {label}
            </span>
          ) : (
            <Linkify componentDecorator={componentHref}>{label}</Linkify>
          )}
        </p>
        {description && <small>{description}</small>}
      </div>
    </label>
  );
};
