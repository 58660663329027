import React, { forwardRef } from "react";

import "./input.styles.scss";

const Input = forwardRef(({ label, name, error, ...rest }, ref) => {
  return (
    <div className={`container-input ${error && "with-error"}`}>
      <label htmlFor={name}>{label}</label>
      <input ref={ref} name={name} {...rest} />

      {error && <div className="error">{error.message}</div>}
    </div>
  );
});

export { Input };
