import React, { createContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import * as ModalActions from "~/store/modules/modal/actions";

import FeedbackSuccess from "../Modals/Success";
import FeedbackAnswersLeft from "../Modals/AnswersLeft";
import FeedbackAlreadyVoted from "../Modals/AlreadyVoted";

import api from "~/services/api";

const FormalConsultationContext = createContext();

const FormalConsultationProvider = ({ files, live, children }) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const currentQuestionRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    mapQuestions();
  }, []);
  useEffect(() => {
    const backToLivestreamPageResetingModal = () => {
      backToLivestreamPage();
      dispatch(ModalActions.resetModalState());
    };

    if (live.user_already_voted) {
      dispatch(
        ModalActions.openModal(
          FeedbackAlreadyVoted(backToLivestreamPageResetingModal),
          () => {
            backToLivestreamPage();
          }
        )
      );
      return;
    }
  }, []);
  const mapQuestions = () => {
    const reversedLiveStreamPolls = live.livestreamPolls.reverse();
    setQuestions(reversedLiveStreamPolls);
  };
  const backToLivestreamPage = () => {
    history.push("/");
  };

  const currentQuestionHandler = () => {
    currentQuestionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const handleChangeAnswer = (pollGuid, selectedOptionGuid, questionIndex) => {
    let data = { ...answers };
    data[pollGuid] = {
      ...data[pollGuid],
      pollGuid,
      pollOptionGuid: selectedOptionGuid,
    };
    setAnswers(data);
    setCurrentQuestion(questionIndex + 1);
    currentQuestionHandler();
  };

  const handleSendVotes = () => {
    const keys = Object.keys(answers);
    if (questions.length !== keys.length) {
      dispatch(
        ModalActions.openModal(
          FeedbackAnswersLeft(handleModalBackButton, handleModalSendVotesButton)
        )
      );
      return;
    }
    sendVotesRequest();
  };

  const handleModalBackButton = () => {
    dispatch(ModalActions.resetModalState());
  };

  const handleModalSendVotesButton = () => {
    dispatch(ModalActions.closeModal(sendVotesRequest));
  };

  const sendVotesRequest = async () => {
    setLoading(true);
    try {
      await api.post(`/v1/livestreams/${live.guid}/polls/votes`, {
        questions: Object.values(answers),
      });
      setLoading(false);
      dispatch(
        ModalActions.openModal(FeedbackSuccess(), () => {
          backToLivestreamPage();
        })
      );
    } catch (e) {
      console.error(e)
      alert("Não conseguimos validar seus dados de acesso, tente novamente!");
      return;
    }
  };

  return (
    <FormalConsultationContext.Provider
      value={{
        questions,
        loading,
        starts_at: live.starts_at,
        ends_at: live.ends_at,
        handleChangeAnswer,
        handleSendVotes,
        sendVotesRequest,
        setCurrentQuestion,
        files,
        assemblyName: live.name,
        currentQuestionRef,
        currentQuestion,
        answers,
      }}
    >
      {children}
    </FormalConsultationContext.Provider>
  );
};

export { FormalConsultationContext, FormalConsultationProvider };
