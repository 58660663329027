import produce from "immer";

const INITIAL_STATE = {
  me: {}
};

export default function me(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@me/me": {
        draft.me = action.payload.me;
        break;
      }
      default:
    }
  });
}
