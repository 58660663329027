import React from "react";

import ExclamationQuestion from "~/assets/imgs/hallo/exclamation-question.svg";

import ButtonPrimary from "~/components/Button/ButtonPrimary";
import ButtonSecondary from "~/components/Button/ButtonSecondary";

import "./style.scss";

export default (backButtonCallback, sendVotesButtonCallback) => {

    return (
        <>
            <div className="modal-formal-consultation">
                <img src={ExclamationQuestion} alt="Exclamation mark icon" />
                <h4 className="modal-formal-consultation__title">
                    Há questões sem respostas...
                </h4>
                <p className="modal-formal-consultation__paragraph">
                    Você pode enviar suas respostas assim, ou voltar e responder as faltantes.
                </p>
                <div className="modal-formal-consultation__actions">
                    <ButtonSecondary onClick={backButtonCallback}
                        className="modal-formal-consultation__actions__btn-back">
                        Voltar
                    </ButtonSecondary>
                    <ButtonPrimary onClick={sendVotesButtonCallback}
                        className="modal-formal-consultation__actions__btn-send-votes">
                        Enviar votação
                    </ButtonPrimary>
                </div>
            </div>
        </>
    )
}