import React, { useState, useContext, useEffect } from "react";
import InputMask from "react-input-mask";
import { LandingPageContext } from "~/pages/LandingPage/LandingPageContext";
import api from "~/services/api";
import Loading from "~/components/Loading";

function Form({ branch, logo }) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [isCNPJ, setCNPJ] = useState(false);
  const [collaborator, setCollaborator] = useState({
    document_number: "",
    agency: "",
    name: "",
    email: "",
    confirmed_email: "",
    code: "",
    is_cooperated: true,
    branch: branch,
    has_company: false,
    company_name: "",
    account_number: "",
    company_document_number: "",
    company_condition: false,
    birthdate: "",
    representative_name: "",
    representative_document_number: ""
  });
  const initialCollaboratorState = {
    document_number: "",
    agency: "",
    name: "",
    email: "",
    confirmed_email: "",
    code: "",
    account_number: "",
    is_cooperated: true,
    branch: branch,
    has_company: false,
    company_name: "",
    company_document_number: "",
    company_condition: false,
    birthdate: "",
    representative_name: "",
    representative_document_number: ""
  };

  const { formRef } = useContext(LandingPageContext);

  useEffect(() => {
    if (agencies.length === 0) {
      api.get(`/v1/agencies?branch=${branch}`)
        .then(({ data: agencies }) => {
          setAgencies(agencies);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  async function submitHandler(e) {
    e.preventDefault();

    setLoading(true);

    if (collaborator.code?.length < 4 || collaborator.code?.length > 8) {
      setError("Informe uma senha entre 4 à 8 digitos.");
      setLoading(false);
      return;
    }

    if (collaborator.email !== collaborator.confirmed_email) {
      setError("Os e-mails são diferentes. Tente novamente.");
      setLoading(false);
      return;
    }

    try {
      await api.post("/v1/livestreams/attendant", collaborator);
      setError("");
      setCollaborator(initialCollaboratorState);
      setCNPJ(false);
      setSuccess("Recebemos o seu cadastro, vamos validar seus dados e em breve lhe enviaremos sua senha de acesso.");
    } catch (err) {
      const error =
        err?.response?.data?.message ||
        "Não foi possível realizar o cadastro, tente novamente!";
      setError(error);
      setSuccess("");
    } finally {
      setLoading(false);
    }
  }

  function handleCpfCnpjChange(event) {
    let data = { ...collaborator };
    const { value } = event.target;
    const oldLength = collaborator.document_number.replace(/\D/g, '').length;
    const newLength = value.replace(/\D/g, '').length;

    const isNewCNPJ = (oldLength === 11 && newLength >= 11) || newLength > 11;

    if (isNewCNPJ !== isCNPJ) {
      setCNPJ(isNewCNPJ);
    }

    data[event.target.name] = value;
    setCollaborator(data);
  };

  function changeHandler(e) {
    let data = { ...collaborator };
    let updatedValue = e.target.value;

    if (updatedValue === "true" || updatedValue == "false") {
      updatedValue = JSON.parse(updatedValue);
    }
    if (e.target.name === "company_condition") {
      updatedValue = e.target.checked;
    }
    if (e.target.name === "code") {
      updatedValue = String(updatedValue).replace(/\D/g, "");
    }
    data[e.target.name] = updatedValue;
    setCollaborator(data);
    setError("");
  }

  return (
    <section className="landing-page-form" ref={formRef}>
      <img className="logo" src={logo} />
      <div className="landing-page-info">
        <p>A Assembleia Geral Ordinária da Uniprime Pioneira será no dia 11 de março de 2024.</p>
        <p>A última chamada será às 19h15 (horário de Brasília).</p>
        <p>Em função do tempo requerido para verificação da regularidade da inscrição dos cooperados na Assembleia, o formulário estará disponível até às 17h (horário de Brasília) do dia 11 de março de 2024.</p>
        <p>Anote na agenda!</p>
      </div>
      <span>
        Preencha o formulário e solicite o seu acesso para participar!
      </span>
      <form onSubmit={submitHandler}>
        <label htmlFor="name">Nome ou Empresa</label>
        <input
          onChange={changeHandler}
          value={collaborator.name}
          required
          name="name"
          id="name"
          type="text"
        />
        <label htmlFor="document_number">CPF ou CNPJ</label>
        <InputMask
          id="document_number"
          name="document_number"
          type="text"
          mask={isCNPJ ? "99.999.999/9999-99" : "999.999.999-999"}
          maskPlaceholder={""}
          maskChar={null}
          onChange={handleCpfCnpjChange}
          value={collaborator.document_number}
          required
        />
        {isCNPJ && (
          <>
            <label htmlFor="representative_name">Nome do Representante</label>
            <input
              onChange={changeHandler}
              value={collaborator.representative_name}
              required
              name="representative_name"
              id="representative_name"
            />
            <label htmlFor="representative_document_number">CPF do Representante</label>
            <InputMask
              onChange={changeHandler}
              value={collaborator.representative_document_number}
              required
              id="representative_document_number"
              name="representative_document_number"
              type="text"
              mask="999.999.999-999"
              maskPlaceholder={""}
              maskChar={null}
            />
          </>
        )}
        <label htmlFor="email">Email</label>
        <input
          onChange={changeHandler}
          value={collaborator.email}
          required
          name="email"
          id="email"
          type="email"
          autoComplete="off"
        />
        <label htmlFor="confirmed_email">Confirme e-mail</label>
        <input
          onChange={changeHandler}
          value={collaborator.confirmed_email}
          required
          name="confirmed_email"
          id="confirmed_email"
          type="email"
          autoComplete="off"
        />
        {!isCNPJ && (
          <>
            <label htmlFor="birthdate">Data de Nascimento</label>
            <InputMask
              onChange={changeHandler}
              value={collaborator.birthdate}
              required
              mask="99/99/9999"
              maskPlaceholder={""}
              maskChar={null}
              name="birthdate"
              id="birthdate"
              type="text"
            />
          </>
        )}
        <label htmlFor="code">Senha (apenas números)</label>
        <input
          onChange={changeHandler}
          value={collaborator.code}
          required
          name="code"
          id="code"
          type="password"
          autoComplete="off"
        />
        <label htmlFor="account_number">Nº da Conta Corrente</label>
        <input
          onChange={changeHandler}
          value={collaborator.account_number}
          required
          name="account_number"
          id="account_number"
          type="number"
        />
        <div className="select">
          <label htmlFor="agency">Selecione sua agência</label>
          <select
            name="agency"
            onChange={changeHandler}
            value={collaborator.agency}
            required
          >
            <option value=""></option>
            {agencies.map((agency) => (
              <option key={agency.guid} value={agency.code}>{agency.name}</option>
            ))}
          </select>
        </div>
        <div className="select">
          <label htmlFor="is_cooperated">Cooperado?</label>
          <select
            name="is_cooperated"
            onChange={changeHandler}
            value={collaborator.is_cooperated}
          >
            <option value={true}>Sim</option>
            <option value={false}>Não</option>
          </select>
        </div>
        {collaborator.has_company && (
          <>
            <label htmlFor="company_name">Razão Social</label>
            <InputMask
              id="company_name"
              name="company_name"
              type="text"
              maskPlaceholder={""}
              onChange={changeHandler}
              value={collaborator.company_name}
              required={collaborator.has_company}
            />
            <label htmlFor="company_document_number">CNPJ</label>
            <InputMask
              id="company_document_number"
              name="company_document_number"
              type="text"
              maskPlaceholder={""}
              mask="99.999.999/9999-99"
              onChange={changeHandler}
              value={collaborator.company_document_number}
              required={collaborator.has_company}
            />
          </>
        )}

        <button type="submit" disabled={loading}>
          {loading ? <Loading size={3} marginAuto /> : "Enviar"}
        </button>
      </form>

      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      <div className="form-bottom"></div>
    </section>
  );
}

export default Form;
