import React from "react";
import { useSelector } from "react-redux";

import "./Logo.sass";

const Logo = () => {
  const logo = useSelector((store) => store.configs.website.logo);

  return (
    <img
      src={logo}
      className="logo-delegates"
      alt="logo ailos"
    />
  );
};

export default Logo;
