import React, { useState, Fragment, useEffect } from "react";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";

import Header from "./Header";
import ChatBox from "./ChatBox";

import * as ChatActions from "~/store/modules/chat/actions";
import { privateChatSocketUrl } from "~/services/socket_url";

import "./Chat.scss";

export default function Chat() {
  const dispatch = useDispatch();
  const me = useSelector((store) => store.me.me);
  const openChat = useSelector((store) => store.chat.open);
  const renderIconChat = useSelector((store) => store.chat.render_icon);
  const allowPrivateChat = useSelector((store) => store.chat.allow_private_chat);
  const livestream = useSelector((store) => store.livestream.livestream);

  const [socket, setSocket] = useState(null);
  const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);

  useEffect(() => {
    if (allowPrivateChat && livestream?.guid) {
      const socketIO = io(privateChatSocketUrl, {
        auth: {
          token: me.socket_token,
        },
        query: {
          assemblyGuid: livestream?.guid,
        },
        withCredentials: true
      });
  
      setSocket(socketIO);

      socketIO.on("total_unread_messages:client", (data) => {
        setTotalUnreadMessages(data.total);
        dispatch(ChatActions.renderIcon(true));
      });

      socketIO.on("disconnect", () => {
        dispatch(ChatActions.chatModal(false));
      });
      
      return () => {
        dispatch(ChatActions.renderIcon(false));
        socketIO.disconnect();
      }
    }

  }, [allowPrivateChat, livestream?.guid]);

  useEffect(() => {
    if (openChat) {
      setTotalUnreadMessages(0);
    }
  }, [openChat]);

  function modalChat() {
    dispatch(ChatActions.chatModal(!openChat));
  }

  return renderIconChat && allowPrivateChat ? (
    <Fragment>
      <div onClick={modalChat} className="container-button-chat">
        <div className={`notification-chat${totalUnreadMessages > 0 ? " notification-on" : " notification-off"}`}>
          {totalUnreadMessages}
        </div>
        {openChat ? <span className="icon-close" /> : <span className="icon-ic_mensagens1" />}
      </div>

      {openChat && (
        <div className="container-box-chat h-chatbox">
          <div>
            <Header className="chat-header">
              <p>{livestream?.branch?.name}</p>
            </Header>
            <ChatBox open={openChat} socket={socket} />
          </div>
        </div>
      )}
    </Fragment>
  ) : null;
}
