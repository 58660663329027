import React, { useRef, useState, useEffect } from "react";
import Play from "./Play";
import Pause from "./Pause";
import Bar from "./Bar";

import "./Audio.scss";
function Audio({ audio }) {
    const [duration, setDuration] = useState();
    const [curTime, setCurTime] = useState();
    const [playing, setPlaying] = useState(false);
    const [clickedTime, setClickedTime] = useState();
    const audioPlayer = useRef()



    function onPause() {
        setPlaying(false);
    }

    function onPlay() {
        setPlaying(true);
    }

    async function playAudio() {
        audioPlayer.current && audioPlayer.current.play();

    }

    function pauseAudio() {
        audioPlayer.current && audioPlayer.current.pause();
    }

    function onTimeUpdate() {
        if (audioPlayer.current) {
            setDuration(audioPlayer.current.duration);
            setCurTime(audioPlayer.current.currentTime);
        }
    }

    useEffect(() => {
        if (audioPlayer.current) {
            audioPlayer.current.addEventListener("pause", onPause)
            audioPlayer.current.addEventListener("play", onPlay)
            audioPlayer.current.addEventListener("timeupdate", onTimeUpdate)
            audioPlayer.current.addEventListener("loadedmetadata", onTimeUpdate)
            audioPlayer.current.addEventListener("loadeddata", onTimeUpdate)
            audioPlayer.current.addEventListener("durationchange", onTimeUpdate)

            if (clickedTime && clickedTime !== curTime) {
                audioPlayer.current.currentTime = clickedTime;
                setClickedTime(null);
            }
            return () => {
                audioPlayer.current.removeEventListener("pause", onPause)
                audioPlayer.current.removeEventListener("play", onPlay)
                audioPlayer.current.removeEventListener("timeupdate", onTimeUpdate)// eslint-disable-line react-hooks/exhaustive-deps
            }
        }
    }, [audioPlayer.current, clickedTime]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="player message">
            <audio ref={audioPlayer} style={{ display: "none" }} controls  >
                <source src={audio} />
            </audio>
            <div className="controls">
                {playing ?
                    <Pause handleClick={pauseAudio} /> :
                    <Play handleClick={playAudio} />
                }
                <Bar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)} />
            </div>
        </div >
    );
}

export default Audio;
