import React, { useState } from "react";
import Audio from "~/components/Audio/Audio";
import Linkify from "react-linkify";
import "./Comment.scss";
function PinnedComment({ componentHref, pinnedComment }) {
  const [showMore, setShowMore] = useState(false);
  return (
    <li
      className="container-li-comment pinned-message"
      style={{ positon: showMore ? "absolute" : "sticky" }}
    >
      <div className="pinned-header">
        <h1>{pinnedComment.from?.name}</h1>
        <span>Mensagem fixada</span>
      </div>
      <div className="pinned-message-container">
        <div className="pinned-content-message">
          {pinnedComment.attachment_type === "mp3" ? (
            <Audio audio={pinnedComment.attachment} />
          ) : pinnedComment.from?.is_admin ? (
            <div className="pinned-message-text">
              <Linkify componentDecorator={componentHref}>
                {showMore
                  ? pinnedComment.message
                  : `${pinnedComment.message.substring(0, 250)}...`}
              </Linkify>
              {pinnedComment?.message?.length > 250 && (
                <button
                  onClick={() => setShowMore((prev) => !prev)}
                  className="pinned-message-text-button"
                >
                  {showMore ? "ver menos" : "ver mais"}
                </button>
              )}
            </div>
          ) : (
            <p className="pinned-message-text">
              {showMore
                ? pinnedComment.message
                : `${pinnedComment.message.substring(0, 150)}...`}
            </p>
          )}
        </div>
      </div>
    </li>
  );
}

export default PinnedComment;
