import React from "react";
import { useController } from "react-hook-form";

import ReactAsyncSelect from "react-select/async";

import "../select.styles.scss";

function AsyncSelect({ label, name, control, error, ...rest }) {
  const {
    field: { onChange, value, ...fieldProps },
  } = useController({
    name,
    control,
  });

  return (
    <div className={`container-input ${error && "with-error"}`}>
      <label htmlFor={name}>{label} </label>

      <ReactAsyncSelect
        {...rest}
        {...fieldProps}
        className="react-select"
        value={value}
        components={{
          IndicatorSeparator: () => null,
        }}
        onChange={onChange}
        defaultOptions
      />

      {error && <div className="error">{error.message}</div>}
    </div>
  );
}

export { AsyncSelect };
