import React, { Fragment } from "react";

import "./Input.scss";

function Component({ line, ...props }) {
  return <input className={`${line ? "line" : null}`} {...props} />;
}

export default function Input({ ...props }) {
  return (
    <Fragment>
      <Component {...props} />
    </Fragment>
  );
}
