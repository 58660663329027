import React, {
  lazy,
  useRef,
  useState,
  Suspense,
  useEffect,
  useCallback,
} from "react";
import VideoPlayer from "./VideoPlayer";
import { useHistory, useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import config from "~/config";
import printerIcon from "~/assets/imgs/hallo/printer.svg";

import "./Livestream.scss";

import io from "socket.io-client";
import api from "~/services/api";

import * as ModalActions from "~/store/modules/modal/actions";

import { useDispatch, useSelector } from "react-redux";
import * as MeActions from "~/store/modules/me/actions";
import * as ChatActions from "~/store/modules/chat/actions";
import * as UserRulesActions from "~/store/modules/user_rules/actions";
import * as LivestreamActions from "~/store/modules/livestream/actions";
import Forum from "./components/Forum/Forum";
import { assemblySocketUrl } from "~/services/socket_url";
import { getToken, removeToken } from "~/services/token";
import { getCurrentDomain } from "~/utils";
import useQuery from "~/hooks/use-query";

import FormalConsultation from "~/pages/Livestream/components/FormalConsultation";
import { FormalConsultationProvider } from "./components/FormalConsultation/Context/FormalConsultationContext";
import LivestreamHeader from "./components/Header/LivestreamHeader";
import Chat from "~/components/Chat";

const Polls = lazy(() => import("./components/Polls/Polls"));
const Results = lazy(() => import("./Results"));
const ModalReview = lazy(() => import("./ModalReview"));
const Navbar = lazy(() => import("~/components/Navbar"));
const ModalNewVote = lazy(() => import("./ModalNewVote"));
const ModalDocument = lazy(() =>
  import("./components/Documents/ModalDocument")
);
const ModalCompanies = lazy(() => import("./ModalCompanies"));
const ModalPresential = lazy(() => import("./ModalPresential"));
const ModalVotedAnotherAssembly = lazy(() =>
  import("./ModalVotedAnotherAssembly")
);
const ModalHasBond = lazy(() => import("./ModalHasBond"));
const ModalId = lazy(() => import("./components/IdRequired/IdRequired"));

export default function Livestream({ match }) {
  const token = getToken();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const dispatch = useDispatch();
  const configs = useSelector((store) => store.configs);

  const [socket, setSocket] = useState(null);
  const [modalCompanies, setModalCompanies] = useState(true);
  const [modalIdRequired, setModalIdRequired] = useState(true);
  const [modalHasBond, setModalHasBond] = useState(true);
  const [modalHasBondNext, setModalHasBondNext] = useState(false);
  const [modalPresential, setModalPresential] = useState(true);
  const [meFinished, setMeFinished] = useState(false);

  const [socketConnected, setSocketConnected] = useState(false);

  const me = useSelector((store) => store.me.me);
  const [livestreamData, setLivestreamData] = useState("");

  //documents
  const [liveDocuments, setLiveDocuments] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [modalDocuments, setModalDocuments] = useState(false);
  const [modalReview, setModalReview] = useState(true);
  const [livestreamOn, setLivestreamOn] = useState(false);
  const [livestreamPlaybackId, setLivestreamPlaybackId] = useState(null);
  const [liveFinished, setLiveFinished] = useState(
    livestreamData && livestreamData.ended_at
  );

  //polls and results
  const [pollData, setPollData] = useState("");
  const [infoPoll, setInfoPollData] = useState(false);
  const [loadingVote, setLoadingVote] = useState(false);
  const [hasPollAlreadyVoted, setHasPollAlreadyVoted] = useState(false);
  const [optionUser, setOptionUser] = useState({
    poll_guid: "",
    poll_option_guid: [],
  });
  const [pollVoteReceived, setOptionReceived] = useState({
    show: false,
    label: "",
  });
  const [pollResult, setPollResult] = useState("");
  const [prePollReady, setPrePollReady] = useState(false);
  const [posPollReady, setPosPollReady] = useState(false);
  const [modalNewVote, setModalNewVote] = useState(false);
  const [votedAnotherAssembly, setVotedAnotherAssembly] = useState(undefined);

  const [companySaved, setCompanySaved] = useState(false);
  const [idSaved, setIdSaved] = useState(
    livestreamData && livestreamData.need_to_send_id
  );
  const [ballotPaperButtonLoading, setBallotPaperButtonLoading] =
    useState(false);
  // zoom
  const [hasZoomRequested, setHasZoomRequested] = useState(false);
  const [isZoomApproved, setZoomIsApproved] = useState(false);
  const [isZoomRejected, setZoomIsRejected] = useState(false);
  const [showZoom, setShowZoom] = useState(false);
  //forum settings

  const [durationTime, setDurationTime] = useState({
    poll: 0,
    results: 0,
  });

  //video
  const [showVideo, setShowVideo] = useState(true);
  const player = useRef(null);
  const videoWrapper = useRef(null);
  const timer = useRef();
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);
  const messageLivestreamEnd =
    "Agradecemos sua presença, assembleia encerrada.";

  useEffect(() => {
    getLiveDocuments();
    return () => {
      dispatch(UserRulesActions.user_rules(null));
      dispatch(ChatActions.livestreamGuid(null));
      dispatch(LivestreamActions.livestream(null));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getLivestreamData();
    dispatch(ChatActions.livestreamGuid(match.params.guid));
    // eslint-disable-next-line
  }, []);

  const livestream = useSelector((store) => store.livestream.livestream);

  useEffect(() => {
    function fetchMe() {
      const url = `/v1/me?assembly_id=${livestream?.id}`;
      api
        .get(url)
        .then(({ data: me }) => {
          dispatch(MeActions.me(me));
          setMeFinished(true);
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            removeToken();
            dispatch(MeActions.me({}));
            history.push(`/`);
          }
        });
    }

    if (token && livestream?.id) fetchMe();
  }, [token, livestream]);

  async function getLivestreamData() {
    try {
      const domain = getCurrentDomain();
      const { data } = await api.get(`/v1/livestreams/${match.params.guid}?domain=${domain}`);

      setLivestreamData(data);
      setShowZoom(data.zoom_enable);
      dispatch(LivestreamActions.livestream(data));

      const playbackId =
        data.pre_poll_enabled && data.pre_poll_playback_id
          ? data.pre_poll_playback_id
          : data.pos_poll_enabled && data.pos_poll_playback_id
          ? data.pos_poll_playback_id
          : data.playback_id;

      setLivestreamPlaybackId(playbackId);
      setLivestreamOn(data.livestream_on);
    } catch (error) {
      history.push("/");
    }
  }

  useEffect(() => {
    if (prePollReady) {
      socket.emit("pre_poll_ready");
    }
    if (posPollReady) {
      socket.emit("pos_poll_ready");
    }
  }, [prePollReady, posPollReady]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let conditions;

    if (livestreamData.type?.type !== "delegates") {
      if (me.is_company) {
        if (me.has_bond) {
          conditions = livestreamData && modalHasBondNext;
        } else {
          conditions = livestreamData;
        }
      } else {
        conditions =
          livestreamData &&
          (!(
            !me.is_guest &&
            me.has_companies &&
            !livestreamData.changed_from_representative_to_guest &&
            livestreamData.can_confirm_company
          ) ||
            companySaved) &&
          !livestreamData.has_presence_another_assembly &&
          ((livestreamData.presential && livestreamData.checkout) ||
          configs.assembly_hybrid_enabled ||
            !livestreamData.presential ||
            livestreamData.pos_poll_enabled ||
            livestreamData.pre_poll_enabled) &&
          (!livestreamData.need_to_send_id || idSaved);
      }
    } else {
      conditions = livestreamData;
    }

    if (conditions) {
      const socket = io(assemblySocketUrl, {
        auth: {
          token: me.socket_token,
        },
        query: {
          assemblyGuid: match.params.guid,
          is_presential: query.get("type") === "presential",
        },
        withCredentials: true,
      });

      setSocket(socket);
      socket.on("connect", () => {
        setSocketConnected(true);
      });

      // mux webhook
      socket.on("stream_update", (data) => {
        if (data?.playback_id) {
          setLivestreamOn(true);
          setLivestreamPlaybackId(data.playback_id);
        } else {
          window.location.reload();
        }
      });

      socket.on("send_feedback", () => {
        setIsFeedbackSent(true);
      });

      socket.on("current_poll", (poll) => {
        clearInterval(timer.current);
        setLoadingVote(false);
        setOptionReceived({
          label: "",
          show: false,
        });
        setDurationTime((prev) => ({
          ...prev,
          poll: poll.time_left,
        }));
        if (document.fullscreenElement) {
          document.exitFullscreen();
        }
        setPollData(poll);
      });

      socket.on("poll_vote_received", (label) => {
        setOptionReceived({
          show: true,
          label,
        });
        setLoadingVote(false);
      });

      socket.on("poll_vote_error", (message) => {
        setLoadingVote(false);

        if (message) {
          toast.error(message);
        }
      });

      socket.on("polls_running_without_access", (time_left) => {
        clearInterval(timer.current);
        setInfoPollData(true);
        setDurationTime((prev) => ({
          ...prev,
          poll: time_left,
        }));
        timer.current = setInterval(() => duration("poll"), 1000);
      });

      socket.on("polls_running_already_voted", (time_left) => {
        clearInterval(timer.current);
        setHasPollAlreadyVoted(true);
        setDurationTime((prev) => ({
          ...prev,
          poll: time_left,
        }));
        timer.current = setInterval(() => {
          duration("poll");
        }, 1000);
      });

      socket.on("poll_ended", (poll) => {
        if (poll) {
          setOptionReceived(false);
          setPollData("");
        }
      });

      socket.on("poll_result", (poll) => {
        clearInterval(timer.current);
        setPollData("");
        setInfoPollData(false);
        setHasPollAlreadyVoted(false);
        setPollResult(poll);
      });

      socket.on("poll_result_ended", (poll) => {
        if (poll) {
          setPollResult("");
        }
      });

      socket.on("live_finished", (finish) => {
        setLiveFinished(true);
        dispatch(ChatActions.renderIcon(false));
      });

      socket.on("invalidate_poll_success", (data) => {
        socket.disconnect();
        socket.connect();
        setModalNewVote(false);
      });

      socket.on("invalidate_poll_error", (data) => {
        setModalNewVote(false);
      });

      socket.on("zoom:user:approved", () => {
        setZoomIsApproved(true);
        setZoomIsRejected(false);
      });

      socket.on("zoom:user:rejected", () => {
        setZoomIsRejected(true);
        setZoomIsApproved(false);
      });
      socket.on("zoom:user:remove", () => {
        window.location.reload();
      });
      socket.on("reload", () => {
        window.location.reload();
      });
      socket.on("zoom_enable", (value) => {
        setShowZoom(value);
      });

      socket.on("voted_another_assembly", (data) => {
        setVotedAnotherAssembly(data.assembly);
      });
      socket.on("stop_timer", () => {
        clearInterval(timer.current);
        setPollData("");
        setInfoPollData(false);
        setHasPollAlreadyVoted(false);
        setPollResult("");
        setDurationTime({
          poll: 0,
          results: 0,
        });
      });

      if (liveFinished) {
        return () => {
          socket.disconnect();
          setSocketConnected(false);
        };
      }
      return () => {
        socket.disconnect();
        setSocketConnected(false);
      };
    }
  }, [
    livestreamData,
    idSaved,
    companySaved,
    me.is_guest,
    me.has_companies,
    modalHasBondNext,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (liveFinished) {
      return () => {
        if (socket) {
          socket.disconnect();
        }
      };
    }
  }, [liveFinished]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (socket) {
      socket.on("user_info", (userInfo) => {
        if (userInfo) {
          dispatch(
            MeActions.me({
              ...me,
              is_representative: userInfo.is_representative,
              is_collaborator: userInfo.is_collaborator,
              companies: userInfo.companies,
              branch_info: {
                ...me.branch_info,
                agency: userInfo.agency_name,
                group: userInfo.group,
              },
            })
          );
          if (
            userInfo.allowed_to_change_vote &&
            !livestreamData.pos_poll_enabled
          )
            setModalNewVote(true);
          dispatch(UserRulesActions.user_rules(userInfo.allowed_to_vote));
        }
      });

      socket.on("user_info:allowed_to_vote", (allowedToVote) => {
        if (allowedToVote) {
          dispatch(UserRulesActions.user_rules(allowedToVote));
        }
      });
    }
  }, [socket, me, livestreamData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (livestreamData && livestreamData.ended_at) {
      setLiveFinished(true);
      dispatch(ChatActions.renderIcon(false));
    }
  }, [livestreamData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getType = query.get("type");

    if (token) {
      if (getType === "presential") {
        setShowVideo(false);
      }
    } else if (getType) {
      history.push({
        pathname: "/",
        search: `?redirect=${location.pathname}?type=presential`,
      });
    } else {
      history.push("/");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSubmitVote({ repeatVotes } = {}) {
    setLoadingVote(true);

    let data = { ...optionUser };
    if (repeatVotes) {
      data.repeat_votes = repeatVotes;
    }

    socket.emit("poll_vote", data);

    setOptionUser({
      poll_guid: "",
      poll_option_guid: [],
    });
  }

  const getLiveDocuments = async () => {
    setLoadingDocuments(true);
    try {
      const response = await api.get(
        `/v1/livestreams/${match.params.guid}/documents`
      );
      setLiveDocuments(response.data);
    } catch (err) {}
    setLoadingDocuments(false);
  };

  function handleDocuments() {
    if (modalDocuments) {
      setModalDocuments(false);
    } else {
      setModalDocuments(true);
      getLiveDocuments();
    }
  }

  function handleBallotPaperButton() {
    setBallotPaperButtonLoading(true);

    api
      .get(`/v1/livestreams/${match.params.guid}/ballot-paper`, {
        responseType: "blob",
      })
      .then(({ data: report }) => {
        const fileBlob = report;

        const blob = new Blob([fileBlob], {
          type: "application/pdf",
        });

        saveAs(blob, "boletim-de-voto.pdf");
      })
      .catch((error) => {
        alert(
          error?.response?.data?.message ||
            "Não foi possivel imprimir o boletim de voto"
        );
      })
      .finally(() => {
        setBallotPaperButtonLoading(false);
      });
  }

  useEffect(() => {
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  useEffect(() => {
    if (pollData && pollData !== "") {
      timer.current = setInterval(() => {
        duration("poll");
      }, 1000);
    }
  }, [pollData]);

  useEffect(() => {
    if (pollResult.length > 0) {
      const [poll] = pollResult;
      setDurationTime((prev) => ({
        ...prev,
        results: poll.time_left,
      }));
      timer.current = setInterval(() => duration("results"), 1000);
    }
  }, [pollResult]);

  function duration(key) {
    setDurationTime((prev) => {
      if (prev[key] - 1 < 1) {
        setPollData("");
        setInfoPollData(false);
        setHasPollAlreadyVoted(false);
        setPollResult([]);
        clearInterval(timer.current);
        return {
          ...prev,
          [key]: 0,
        };
      } else {
        return {
          ...prev,
          [key]: prev[key] - 1,
        };
      }
    });
  }

  function confirmChangeVotes() {
    socket.emit("invalidate_poll");
  }

  function onRequiredIdClose() {
    dispatch(ModalActions.closeModal(() => {}));

    getLivestreamData();
  }

  const handleApproveZoomRequest = useCallback(() => {
    setHasZoomRequested(true);

    socket.emit("zoom:user:request", me.guid);
  }, [socket, me.guid]);

  return (
    <>
      <Suspense
        fallback={
          <div className="suspense-screen-live">
            <div className="suspense-navbar" />
            <div className="suspense-container-live-comment">
              <div className="suspense-video" />
              <div className="suspense-comments" />
            </div>
          </div>
        }
      >
        <div className="container-fluid-livestream">
          <Navbar typeOfAccess={query.get("access_type")} />
          {livestreamData &&
          livestreamData?.type?.name === "Consulta Formal" ? (
            <FormalConsultationProvider
              files={liveDocuments}
              live={livestreamData}
            >
              <FormalConsultation />
            </FormalConsultationProvider>
          ) : (
            <>
              {livestreamData && (
                <div className={`container-live`}>
                  {modalDocuments && (
                    <Suspense fallback={<div />}>
                      <ModalDocument
                        live_documents={liveDocuments}
                        handleDocuments={handleDocuments}
                        loadingDocuments={loadingDocuments}
                      />
                    </Suspense>
                  )}
                  {modalNewVote && (
                    <Suspense fallback={<div />}>
                      <ModalNewVote
                        confirmChangeVotes={confirmChangeVotes}
                        setModalNewVote={setModalNewVote}
                      />
                    </Suspense>
                  )}
                  {votedAnotherAssembly ? (
                    <Suspense fallback={<div />}>
                      <ModalVotedAnotherAssembly />
                    </Suspense>
                  ) : livestreamData.has_presence_another_assembly ? (
                    <Suspense fallback={<div />}>
                      <ModalPresential message="Identificamos um checkin em uma assembleia do mesmo tipo e ano/exercício. Realize o checkout na recepção do evento para participar da assembleia de forma digital." />
                    </Suspense>
                  ) : !configs.assembly_hybrid_enabled &&
                    livestreamData.presential &&
                    !livestreamData.checkout &&
                    !livestreamData.pos_poll_enabled &&
                    modalPresential ? (
                    <Suspense fallback={<div />}>
                      <ModalPresential
                        onClose={() => setModalPresential(false)}
                      />
                    </Suspense>
                  ) : livestreamData.type?.type !== "delegates" &&
                    !me.is_company &&
                    !me.is_guest &&
                    me.has_companies &&
                    !livestreamData.changed_from_representative_to_guest &&
                    livestreamData.can_confirm_company &&
                    modalCompanies ? (
                    <Suspense fallback={<div />}>
                      <ModalCompanies
                        allowedUser={livestreamData.allowed_user}
                        onClose={() => setModalCompanies(!modalCompanies)}
                        setCompanySaved={setCompanySaved}
                      />
                    </Suspense>
                  ) : null}
                  {livestreamData.type?.type !== "delegates" &&
                    me.is_company &&
                    modalHasBond &&
                    me.has_bond && (
                      <Suspense fallback={<div />}>
                        <ModalHasBond
                          setModalHasBondNext={setModalHasBondNext}
                          onClose={() => setModalHasBond(!modalHasBond)}
                        />
                      </Suspense>
                    )}
                  {livestreamData.need_to_send_id && modalIdRequired && (
                    <Suspense fallback={<div />}>
                      <ModalId
                        onClose={() => setModalIdRequired(!modalIdRequired)}
                        setIdSaved={setIdSaved}
                        guid={match.params.guid}
                      />
                    </Suspense>
                  )}
                  <LivestreamHeader
                    handleDocuments={handleDocuments}
                    totalDocuments={liveDocuments.length}
                    livestream={livestreamData}
                    durationTime={durationTime}
                    isZoomApproved={isZoomApproved}
                    isZoomRejected={isZoomRejected}
                    hasZoomRequested={hasZoomRequested}
                    showZoom={showZoom}
                    handleApproveZoomRequest={handleApproveZoomRequest}
                    livestreamGuid={match.params.guid}
                    socket={socket}
                  />
                  <div
                    className={`container-live-content ${
                      pollData ||
                      infoPoll ||
                      hasPollAlreadyVoted ||
                      pollResult.length > 0
                        ? "with-polls"
                        : ""
                    }`}
                  >
                    {liveFinished &&
                      livestreamData.has_answered_feedback &&
                      !showVideo && (
                        <div className="video-player-container">
                          <div className="finished-live">
                            <p>{messageLivestreamEnd}</p>
                          </div>
                        </div>
                      )}
                    <div className={`container-live-video`}>
                      {socketConnected && isZoomApproved ? (
                        <div
                          className={`zoom-embbeded-container ${
                            !pollData &&
                            !infoPoll &&
                            !hasPollAlreadyVoted &&
                            pollResult.length === 0
                              ? ""
                              : "zoom-poll"
                          } :`}
                        >
                          <div id="meetingSDKElement"></div>
                        </div>
                      ) : showVideo ? (
                        <div
                          className={`live-video-player-holder${
                            !pollData &&
                            !infoPoll &&
                            !hasPollAlreadyVoted &&
                            pollResult.length === 0
                              ? " container-live-video-full"
                              : ""
                          }`}
                        >
                          <div
                            className={`live-video-player-wrapper${
                              !pollData &&
                              !infoPoll &&
                              !hasPollAlreadyVoted &&
                              pollResult.length === 0
                                ? " container-live-video-full"
                                : ""
                            }`}
                          >
                            {(liveFinished ||
                              (isFeedbackSent &&
                                !livestreamData.pos_poll_enabled &&
                                !livestreamData.pre_poll_enabled)) &&
                              !livestreamData.has_answered_feedback &&
                              modalReview && (
                                <Suspense fallback={<div />}>
                                  <div className="review-container-wrap">
                                    <ModalReview
                                      onClose={() =>
                                        setModalReview(!modalReview)
                                      }
                                      guid={match.params.guid}
                                    />
                                  </div>
                                </Suspense>
                              )}
                            {(liveFinished ||
                              (isFeedbackSent &&
                                !livestreamData.pos_poll_enabled &&
                                !livestreamData.pre_poll_enabled)) &&
                            (livestreamData.has_answered_feedback ||
                              !modalReview) ? (
                              <div className="video-player-container">
                                <div className="finished-live">
                                  <p>{messageLivestreamEnd}</p>
                                </div>
                              </div>
                            ) : (
                              socketConnected && (
                                <VideoPlayer
                                  className="video-player-container"
                                  playbackId={livestreamPlaybackId}
                                  playerRef={player}
                                  prePollTriggerPercentage={
                                    livestreamData.pre_poll_percentage_trigger
                                  }
                                  posPollTriggerPercentage={
                                    livestreamData.pos_poll_percentage_trigger
                                  }
                                  setPrePollReady={setPrePollReady}
                                  setPosPollReady={setPosPollReady}
                                  pre_poll_enabled={
                                    livestreamData.pre_poll_enabled
                                  }
                                  pos_poll_enabled={
                                    livestreamData.pos_poll_enabled
                                  }
                                  await_image={livestreamData.await_image}
                                  livestreamOn={livestreamOn}
                                  socketConnected={socketConnected}
                                />
                              )
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {pollResult.length > 0 && !liveFinished && (
                      <div className="container-poll-mobile">
                        <Suspense fallback={<div />}>
                          <Results
                            poll_result={pollResult}
                            setPollResult={setPollResult}
                            polls_result_data={pollResult}
                          />
                        </Suspense>
                      </div>
                    )}
                    {(pollData || infoPoll || hasPollAlreadyVoted) &&
                      pollResult.length === 0 &&
                      !liveFinished && (
                        <Suspense fallback={<div />}>
                          <Polls
                            poll_data={pollData}
                            info_poll={infoPoll}
                            poll_result={pollResult}
                            loading_vote={loadingVote}
                            setPollData={setPollData}
                            setPollResult={setPollResult}
                            setOptionUser={setOptionUser}
                            setInfoPollData={setInfoPollData}
                            handleSubmitVote={handleSubmitVote}
                            poll_vote_received={pollVoteReceived}
                            hasPollAlreadyVoted={hasPollAlreadyVoted}
                            prePollEnabled={livestreamData.pre_poll_enabled}
                            posPollEnabled={livestreamData.pos_poll_enabled}
                            handleBallotPaperButton={handleBallotPaperButton}
                            ballotPaperButtonLoading={ballotPaperButtonLoading}
                            configs={configs}
                          />
                        </Suspense>
                      )}
                    {config.showBallotPaper &&
                      livestreamData.pre_poll_enabled &&
                      pollData &&
                      !infoPoll &&
                      !hasPollAlreadyVoted &&
                      !pollVoteReceived.show && (
                        <div className="ballot-paper-button">
                          <button
                            disabled={ballotPaperButtonLoading}
                            onClick={handleBallotPaperButton}
                            title="Boletim de Voto"
                          >
                            <img src={printerIcon} alt="" />
                          </button>
                        </div>
                      )}
                    <Forum
                      isZoomApproved={isZoomApproved}
                      isZoomRejected={isZoomRejected}
                      hasZoomRequested={hasZoomRequested}
                      handleApproveZoomRequest={handleApproveZoomRequest}
                      livestreamGuid={match.params.guid}
                      livestream={livestreamData}
                      liveFinished={liveFinished}
                      livestreamSocketUrl={livestreamData.socket_url}
                      meFinished={meFinished}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {meFinished && <Chat />}
      </Suspense>
    </>
  );
}
