import React from 'react';

export default class Collapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }

  handleCollapse = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return this.props.children(this.state.isOpen, this.handleCollapse);
  }
}
