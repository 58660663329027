import React from "react";

import CheckSuccessIcon from "~/assets/imgs/hallo/check-success.svg";

import "./style.scss";

export default () => {
 
    return (
        <>
            <div className="modal-formal-consultation">
                <img src={CheckSuccessIcon} alt="Check success icon" />
                <h4 className="modal-formal-consultation__title">Sua votação foi computada!</h4>
                <p className="modal-formal-consultation__paragraph">
                    Agradecemos sua disponibilidade, sua participação nos votos é
                    muito importante!
                </p>
            </div>
        </>
    )
}