import React, { forwardRef } from "react";

import { useController } from "react-hook-form";

import ReactInputMask from "react-input-mask";

import "../input.styles.scss";

const InputMask = forwardRef(
  ({ label, name, control, error, ...rest }, ref) => {
    const {
      field: { onChange, value, ...fieldProps },
    } = useController({
      name,
      control,
      rules: { required: true },
    });
    return (
      <div className={`container-input ${error && "with-error"}`}>
        <label htmlFor={name}>{label}</label>
        <ReactInputMask
          ref={ref}
          name={name}
          value={value}
          onChange={(props) => onChange(props.target.value)}
          {...fieldProps}
          {...rest}
        />

        {error && <div className="error">{error.message}</div>}
      </div>
    );
  }
);

export { InputMask };
