import React from "react";

export default ({ className, setDisabled, children, ...props }) => {
  let classes = `button ${className}`;
  return (
    <button className={classes} {...props}>
      {children}
    </button>
  );
};
