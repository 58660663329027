import React from "react"
import "./CommentsHeader.scss"
import OnlineUsers from "./OnlineUsers"

function CommentsHeader ({socket}){
    return (
        <div className="comments-header">
        <span className="icon icon-ic_mensagem" />
        <p>Bate papo</p>
        <OnlineUsers socket={socket} />
      </div>
    )
}

export default CommentsHeader
