import React from "react";

function Footer({ logo, subLogo }) {
  const styles = {
    justifyContent: subLogo ? "space-between" : "center",
  };

  return (
    <footer className="footer" style={styles}>
      <img src={logo} alt="logo" />
      {subLogo && <img src={subLogo} alt="social medias logo" />}
    </footer>
  );
}

export default Footer;
