import React, { useEffect, useState } from "react";
import "./styles/default.css";
import "./styles/global.scss";
import "./styles/icons-guide.css";

import { Router } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import SimpleReactLightbox from "simple-react-lightbox";

import { store } from "./store";
import Routes from "./routes";
import history from "./services/history";

import Loading from "./components/Loading";
import {
  configs,
  captchaEnable,
  assemblyHybridEnabled,
  setBottomBarContent,
  setNoticeRuleTypeYear,
  setNoticeVotingPermission
} from "./store/modules/configs/actions";
import config from "./config";
import api from "~/services/api";

import BottomBar from "./components/BottomBar";
import Modal from "./components/Modal";
import ToastContainerWrapper from "./components/Toast";
import { getCurrentDomain } from "~/utils";

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <WrapperConfig>
          <SimpleReactLightbox>
            <Modal />
            <Routes />
            <ToastContainerWrapper />
            <BottomBar />
          </SimpleReactLightbox>
        </WrapperConfig>
      </Router>
    </Provider>
  );
}

function WrapperConfig({ children }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStyles();
  }, []);

  async function getStyles() {
    setLoading(true);
    try {
      const domain = getCurrentDomain();
      const { data } = await api.get(`/v1/configs?domain=${domain}`);
      setStyles("--primary_color", data.primary_color);
      setStyles("--secondary_color", data.secondary_color);
      setStyles("--primary_text_color", data.primary_text_color);
      setStyles("--secondary_text_color", data.secondary_text_color);
      setStyles("--boxshadow_color", data.boxshadow_color);
      setStyles("--formal_consultation_color", data.formal_consultation_color);
      setStyles("--prepoll_color", data.prepoll_color);
      setStyles("--winner_color", data.winner_color);
      setStyles("--background_color", data.background_color);
      setStyles("--background_image", `url(${data.background_image})`);
      dispatch(
        configs({
          logo: data.logo,
          backgroundImage: data.background_image,
          primaryTextColor: data.primary_text_color,
        })
      );
      dispatch(captchaEnable(data.captcha_enable));
      dispatch(assemblyHybridEnabled(data.assembly_hybrid_enabled));
      if (data.bottom_bar_content_enabled && data.bottom_bar_content?.trim()) {
        dispatch(setBottomBarContent(data.bottom_bar_content));
      }
      dispatch(setNoticeRuleTypeYear(data.notice_rule_type_year));
      dispatch(setNoticeVotingPermission(data.notice_voting_permission));
      document.title = data.title || config.title;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function setStyles(cssVariable, color) {
    if (!color) return;
    document.documentElement.style.setProperty(cssVariable, color);
  }
  return loading ? <div /> : children;
}

export default App;
