import React, { createContext, useRef } from "react";
export const LandingPageContext = createContext();

export function LandingPageProvider({ children }) {
  const formRef = useRef(null);

  const titles = {
    590: {
      logo: ["Escolha", "Participar"],
      introduction: {
        cooperative: "A Unicred Vale",
        assembly: "À PÓS-ASSEMBLEIA",
        texts: [],
      },
      form: {
        title:
          "Agora que você está por dentro das informações da Pré-Assembleia, anote na agenda: a Assembleia Geral da Unicred Vale será no dia 19 de abril.",
      },
      president:
        "Veja os destaques do exercício na mensagem do nosso presidente, Dr. Wálmore Pereira de Siqueira Junior.",
      agency:
        "Confira como foi o ano para a sua agência no vídeo do seu Gerente de Relacionamento.",
      record: {
        title: ["Um ano com", "resultado", "recorde"],
        text: "Você acreditou na Unicred Vale e a cooperativa atingiu em 2021 o melhor resultado de sua história. Veja o resumo dos números.",
      },
      footer: {
        phone: "(47) 99139-6544",
        email: "ago2022@unicred.com.br",
      },
    },
    582: {
      introduction: {
        cooperative: "A Unicred União",
        assembly: "À PRÉ-ASSEMBLEIA",
        texts: [
          "Neste portal você acompanha os resultados e realizações da cooperativa no ano de 2021.",
          "No formulário ao final da página confirme sua participação na Assembleia Geral do dia 27 de abril, que será realizada de modo virtual.",
        ],
        bottom: `A solidariedade também faz parte deste momento. A Unicred União fará uma doação de alimentos a entidades assistenciais da área de atuação, proporcional à participação dos cooperados na AGO. Além de exercer o seu papel de dono da cooperativa, você transforma realidades.`,
      },
      president:
        "Veja os destaques do exercício na mensagem do nosso presidente, Dr. Mauro Marquiotti.",
      agency:
        "Confira no vídeo do Gerente da sua agência como foi o ano de 2021.",
      record: {
        title: ["Conheça os", "nossos", "resultados"],
        text: "Veja um resumo do Relatório de Gestão e acompanhe de perto tudo o que acontece na sua cooperativa.",
      },
      form: {
        title:
          "Agora que você está por dentro das informações da Pré-Assembleia, anote na agenda: A Assembleia geral da Unicred União será no dia 27 de abril.",
        aditionalInfo:
          "Cooperado Pessoa Jurídica: Solicite o acesso à Assembleia Geral enviando e-mail para administracao.0582@unicred.com.br incluindo os seguintes dados: denominação social, CNPJ, telefone e e-mail cadastrados na cooperativa, além do nome completo, CPF e e-mail do representante legal que votará na assembleia em nome da pessoa jurídica. No caso de cooperado pessoa jurídica com mais de um representante legal, considerando que cada cooperado possui direito a um voto, será necessário anexar ao e-mail o Termo de Nomeação e Representante Legal preenchido e assinado, disponível abaixo em “Saiba Mais” especificando quem será o único representante com acesso à votação. O e-mail, com todas as informações acima descritas deverá ser encaminhado até às 15h30 do dia 27/04/2022, sob pena de inviabilizar a participação e votação.",
      },
      footer: {
        phone: "(47) 99260-2055",
        email: "administracao.0582@unicred.com.br",
      },
    },
    515: {
      logo: ["Escolha", "Participar"],
      introduction: {
        cooperative: "A Unicred Valor Capital",
        assembly: "À ASSEMBLEIA",
        texts: [
          "Aqui você confere os resultados e realizações da cooperativa em 2021. Anote na agenda: dia 26/04/2022 última chamada às 19 horas acesso via internet banking ou Mobile.",
        ],
        bottom: "CONFIRA O NOSSO RESULTADO RECORDE EM 2021",
      },
      president:
        "Veja os destaques do exercício na mensagem do nosso presidente, Dr. Carlos Gilberto Crippa.",
      record: {
        title: ["Conheça os", "nossos", "resultados"],
        text: "Veja um resumo do Balanço Patrimonial e acompanhe de perto tudo o que acontece na sua cooperativa.",
      },
      footer: {
        topInfos:
          "Agora que você está por dentro das informações da Assembleia, anote na agenda: a Assembleia Geral da Unicred Valor Capital será no dia 26 de abril. ",
        phone: "(48) 99623-8954",
        email: "marketing.florianopolis@unicred.com.br",
      },
    },
  };

  const videos = {
    590: {
      agencies: {
        Blumenau:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Blumenau+-+Elton.mp4",
        Brusque:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Brusque+-+Felipe.mp4",
        Gaspar:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Gaspar+-+Tatiana.mp4",
        Ibirama:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Ibirama+-+Walter.mp4",
        Indaial:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Indaial+-+Giovane.mp4",
        Ituporanga:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Ituporanga+-+Patri%CC%81cia.mp4",
        Londrina:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Londrina+-+Adilson.mp4",
        Maringa:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Maringa%CC%81+-+Luciano.mp4",
        Pomerode:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Pomerode+-+Adriano.mp4",
        "Rio do Sul":
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Rio+do+Sul+-+Walter.mp4",
        Timbo:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Timbo%CC%81+-+Adriano.mp4",
      },
      president:
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Dr.+Wa%CC%81lmore+(alterac%CC%A7o%CC%83es+28-03).mp4",
      realize:
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Realizac%CC%A7o%CC%83es+2022.mp4",
      record:
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Balanc%CC%A7o+Patrimonial+-+He%CC%81lio.mp4",
    },
    582: {
      agencies: {
        "Agência Mais":
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Eduardo+-+Age%CC%82ncia+Mais.mp4",
        Batel:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Sergio+-+Batel.mp4",
        Canoinhas:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Janaina+-+Canoinhas.mp4",
        CHU: "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Vera+-+CHU.mp4",
        Copan:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Sergio+-+Copan.mp4",
        Itajaí:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Elo%CC%81i+-+Itajai%CC%81.mp4",
        Itapema:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Elo%CC%81i+-+Itapema.mp4",
        Jaraguá:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Marcos+-+Jaragua%CC%81.mp4",
        Joinville:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Marcos+-+Joinville.mp4",
        Mafra:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Daiane+-+Mafra.mp4",
        Navegantes:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Aline+-+Navegantes.mp4",
        Pioneiros:
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Minati+-+Pioneiros.mp4",
        "Ponta Grossa":
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Soeli+-+Ponta+Grossa.mp4",
        "Porto União":
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Pamela+-+Porto+Unia%CC%83o.mp4",
        "Quarta Avenida":
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Minati+-+Quarta+Avenida.mp4",
        "São Bento do Sul":
          "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Angelita+-+Sa%CC%83o+Bento+do+Sul.mp4",
      },
      president:
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Presidente+-+Unia%CC%83o.mp4",
      realize:
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Realizac%CC%A7o%CC%83es_Unia%CC%83o_-_Alterac%CC%A7o%CC%83es_31-03+(1).mp4",
      record:
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Realizac%CC%A7o%CC%83es_Unia%CC%83o_-_Alterac%CC%A7o%CC%83es_31-03+(1).mp4",
    },
    515: {
      president:
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/ValorCapital/Mensagem_Presidente+(1).mp4",
      record:
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/ValorCapital/Balanc%CC%A7o_Patrimonial_2022_Valor_Capital+(1).mp4",
      responsability:
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/ValorCapital/video-low-res-somar_v3_(2).mov",
      achievements:
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/ValorCapital/Central_Conexa%CC%83o_%2B_Valor_Capital_(1).mp4",
    },
  };

  const documents = {
    590: {
      Edital:
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/%5BA3%5D+CARTAZ+EDITAL+-+AGO+2022+-+VALE+EUROPEU+(1)+-+Aprovado.pdf",
      "Relatório Gestão":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/RELATO%CC%81RIO+DE+GESTA%CC%83O+-+UNICRED+VALE+EUROPEU+-+2018-2021+(11).pdf",
      "Chapa Conselho de Administração":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/CHAPA+CONSELHO+DE+ADMINISTRAC%CC%A7A%CC%83O.jpg",
      "Estatuto Social":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Estatuto+Social+2021+-+REGISTRADO+JUCESC.pdf",
      "Chapa Conselho Fiscal":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/CHAPA+CONSELHO+FISCAL.jpg",
      "Tutorial de Acesso Computador":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Tutorial+de+Acesso+Computador+-+Unicred+Vale+Europeu.pdf",
      "Tutorial de Acesso Celular":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Tutorial+de+Acesso+Smartphone+-+Unicred+Vale+Europeu.pdf",
      "Política de Sucessão de Administradores":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Anexo_CC_2022.006_Poli%CC%81tica_de_Sucessa%CC%83o_de_Administradores_-_final_assembleias_2022.pdf",
      "Política de Conformidade":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Poli%CC%81tica_de_Conformidade_v.final.pdf",
      "Demonstrações Contábeis":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Demonstrac%CC%A7o%CC%83es_Conta%CC%81beis.pdf",
    },
    582: {
      "Termo de Nomeação de Representante Legal":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/TERMO+DE+NOMEAC%CC%A7A%CC%83O+DE+REPRESENTANTE+LEGAL.docx",
      "Demonstrações Financeiras":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Demonstrac%CC%A7o%CC%83es_financeiras.pdf",
      "Parecer do Conselho Fiscal":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Parecer_do_Conselho_Fiscal.pdf",
      "Chapa do Conselho Fiscal":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Chapa_Conselho_fiscal.pdf",
      "Política de Sucessão de Administradores":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Poli%CC%81tica_de_Sucessa%CC%83o_de_Administradores.pdf",
      "Edital de Convocação":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Edital_de_Convocac%CC%A7a%CC%83o.pdf",
      "Política de Conformidade":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Poli%CC%81tica_de_Conformidade.pdf",
      "Relatório de Gestão 2021":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/RELATO%CC%81RIO_DE_GESTA%CC%83O_2021.pdf",
      "Tutorial de Acesso Computador":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Manual_Unicred_-_Web.pdf",
      "Tutorial de Acesso Celular":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/Uniao/Manual_Unicred_-_Mobile+(1).pdf",
    },
    515: {
      "Edital Convocação":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/ValorCapital/Edital_Convocacao_Digital.pdf",
      "Tutorial de Acesso Computador":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/ValorCapital/Manual_Unicred_-_Desktop.pdf",
      "Tutorial de Acesso Celular":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/ValorCapital/Manual_Unicred_-_Mobile.pdf",
      "Relatório de Gestão":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/ValorCapital/Unicred_-_Relato%CC%81rio_de_Gesta%CC%83o_2021.pdf",
      "Demonstrações Financeiras":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/ValorCapital/Unicred_Valor_Capital_DF_31122021_assinada_auditoria_contadora.pdf",
      "Estatuto Social":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/ValorCapital/Estatuto_Social_Valor_Capital_2021_-_ARQUIVADO_JUCESC+(2).pdf",
      "Eleição Conselho Fiscal":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/ValorCapital/Crite%CC%81rios_CF.pdf",
      "Regimento Eleitoral":
        "https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2022/ValorCapital/Regimento_Eleitoral_220222.pdf",
    },
  };

  const layout = {
    590: {
      introduction: true,
      president: false,
      agency: false,
      realize: false,
      record: false,
      responsability: false,
      achievements: false,
      form: false,
      pos: true,
      footer: true,
      colors: {
        introduction: "#323232",
        president: "#3B3B3B",
        agency: "#F7F4EE",
        realize: "#3B3B3B",
        record: "#F7F4EE",
        pos: "#3B3B3B",
        form: "#3B3B3B",
        footer: "#323232",
      },
    },
    582: {
      introduction: true,
      president: true,
      agency: true,
      realize: false,
      record: true,
      form: true,
      pos: false,
      responsability: false,
      achievements: false,
      footer: true,
      colors: {
        introduction: "#323232",
        president: "#3B3B3B",
        agency: "#F7F4EE",
        record: "#3B3B3B",
        form: "#F7F4EE",
        footer: "#323232",
      },
      styles: {
        introduction: {
          fontSize: "1.8rem",
          fontWeight: "normal",
        },
        form: {
          text: {
            color: "#111111",
          },
          border: {
            bottom: {
              borderBottom: "1px solid #111111",
            },
            form: {
              borderBottom: "1px solid #111111",
              borderRight: "1px solid #111111",
              borderLeft: "1px solid #111111",
            },
          },
        },
        record: {
          text: {
            color: "#FFFFFF",
          },
          stroke: {
            webkitTextStrokeColor: "#FFFFFF",
          },
        },
      },
    },
    515: {
      introduction: true,
      president: true,
      agency: false,
      realize: false,
      record: true,
      responsability: true,
      achievements: true,
      form: false,
      footer: true,
      pos: false,
      colors: {
        introduction: "#323232",
        president: "#3B3B3B",
        agency: "#F7F4EE",
        realize: "#3B3B3B",
        record: "#F7F4EE",
        achievements: "#3B3B3B",
        responsability: "#F7F4EE",
        form: "#3B3B3B",
        footer: "#323232",
      },
      styles: {},
    },
  };

  const form = {
    590: {
      hasCompany: true,
    },
    582: {
      hasCompany: false,
    },
  };
  return (
    <LandingPageContext.Provider
      value={{
        formRef,
        videos,
        documents,
        layout,
        titles,
        form,
      }}
    >
      {children}
    </LandingPageContext.Provider>
  );
}
