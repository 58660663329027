import React, { useContext } from "react";
import ButtonSecondary from "~/components/Button/ButtonSecondary";
import Question from "./Question";

import CalendarIcon from "~/assets/imgs/hallo/calendar.svg";
import FileIcon from "~/assets/imgs/hallo/file.svg";

import moment from "moment";
import "moment/locale/pt-br";

import "./style.scss";
import ButtonPrimary from "~/components/Button/ButtonPrimary";
import { FormalConsultationContext } from "./Context/FormalConsultationContext";

export default () => {
  moment.locale("pt-BR");

  const {
    questions,
    loading,
    starts_at,
    ends_at,
    files,
    assemblyName,
    handleChangeAnswer,
    handleSendVotes,
    currentQuestionRef,
    currentQuestion,
    setCurrentQuestion,
    answers,
  } = useContext(FormalConsultationContext);

  const moment_start_date = moment(starts_at);
  const moment_end_date = moment(ends_at);

  const handleDownloadAll = () => {
    files.forEach(async (file) => {
      const a = document.createElement("a");
      a.href = file.document_url;
      a.setAttribute("download", file.name);
      a.setAttribute("target", "_blank");
      await a.click();
    });
  };

  return (
    <div className="formal-consultation">
      <div className="formal-consultation__header">
        <h1 className="formal-consultation__header__title">{assemblyName}</h1>
        <div className="formal-consultation__header__period-vote">
          <img src={CalendarIcon} alt="Calendar icon" />
          <span>
            Período para votação:{" "}
            {`${moment_start_date.format("DD")}
                        ${
                          moment_start_date.format("MMMM") ===
                            moment_end_date.format("MMMM") &&
                          moment_start_date.format("YYYY") ===
                            moment_end_date.format("YYYY")
                            ? ""
                            : " de " + moment_start_date.format("MMMM")
                        }
                        ${
                          moment_start_date.format("YYYY") ===
                          moment_end_date.format("YYYY")
                            ? ""
                            : " de " + moment_start_date.format("YYYY")
                        }
                        à
                        ${moment_end_date.format(
                          "DD"
                        )} de ${moment_end_date.format("MMMM")} de
                        ${moment_end_date.format("YYYY")}`}
          </span>
        </div>
      </div>
      <div className="formal-consultation__container">
        <div className="formal-consultation__container__file-card">
          {files.length > 0 ? (
            <>
              <h5 className="formal-consultation__container__file-card__title">
                Arquivos
              </h5>
              <div className="formal-consultation__container__file-card__file-list">
                {files.map((file) => (
                  <div className="formal-consultation__container__file-card__file-list__item">
                    <img src={FileIcon} alt="File icon" />
                    <a
                      href={file.document_url}
                      target="_blank"
                      className="formal-consultation__container__file-card__file-list__item__link"
                    >
                      {file.name}
                    </a>
                  </div>
                ))}
              </div>
              <ButtonSecondary
                onClick={handleDownloadAll}
                className="formal-consultation__container__file-card__download-all"
              >
                Baixar todos os arquivos
              </ButtonSecondary>
            </>
          ) : (
            <p className="formal-consultation__container__file-card__no-files">
              Essa assembléia não possui arquivos
            </p>
          )}
        </div>
        <div className="formal-consultation__questions">
          <div className="formal-consultation__questions-list">
            {questions.map((question, i) => (
              <Question
                currentQuestionRef={currentQuestionRef}
                currentQuestion={currentQuestion}
                question={question}
                handleChangeAnswer={handleChangeAnswer}
                key={question.guid}
                answers={answers}
                questionIndex={i}
                setCurrentQuestion={setCurrentQuestion}
              />
            ))}
          </div>
          <ButtonPrimary
            onClick={handleSendVotes}
            className="formal-consultation__container__send-votation"
            disabled={loading}
          >
            Enviar votação
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};
