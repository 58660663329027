import React from "react";

function Countdown({ durationTime }) {
  function formatTime(time) {
    time = Math.round(time);
    if (time < 60) {
      return time + " seg";
    } else {
      const secs = time % 60;
      const minutes = Math.floor(time / 60);
      return minutes + " min" + (secs > 0 ? ` e ${secs} seg` : "");
    }
  }
  return (
    <>
      {Math.round(durationTime.poll) > 0 && durationTime.results === 0 && (
        <span className="time-display">
          <strong>Tempo para votação:</strong> {formatTime(durationTime.poll)}
        </span>
      )}

      {durationTime.poll < 1 && Math.round(durationTime.results) > 0 && (
        <span className="time-display">
          <strong>Tempo de resultado:</strong>{" "}
          {formatTime(durationTime.results)}
        </span>
      )}
    </>
  );
}

export default Countdown;
