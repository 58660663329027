import React from "react";
import style from "./LandingPage.module.css";
function Background({
  children,
  backgroundColor,
  backgroundImage,
  leftCurves,
  rightCurves,
  styles,
}) {
  return (
    <div
      className={style.outterContainer}
      style={{
        backgroundImage: `${
          backgroundImage ? `url(${backgroundImage})` : `${backgroundColor}`
        }`,
      }}
    >
      {leftCurves && (
        <div className={style.leftCurves}>
          <img src={leftCurves} alt="linhas curvadas para esquerda" />
        </div>
      )}
      {rightCurves && (
        <div className={style.rightCurves}>
          <img src={rightCurves} alt="linhas curvadas para direita" />
        </div>
      )}
      <div className={`${style.innerContainer} ${styles ? styles : ""}`}>
        {children}
      </div>
    </div>
  );
}

export default Background;
