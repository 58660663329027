import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import ButtonSecondary from "~/components/Button/ButtonSecondary";
import api from "~/services/api";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import "./ZoomRequest.scss";
import { CameraIcon } from "~/components/icons";
function ZoomRequest({
  isStreamming,
  handleApproveZoomRequest,
  hasZoomRequested,
  isZoomRejected,
  livestreamGuid,
  isZoomApproved,
  socket,
}) {
  const [zoomClient, setZoomCLient] = useState(null);
  const me = useSelector((store) => store.me.me);
  const initialIconColor = useSelector((store) => store.configs.website.primaryTextColor);
  const [iconColor, setIconColor] = useState("");
  useEffect(() => {
    createZoomClient();
    getIconColor();
    if (isZoomApproved) {
      getSignature();
    }
  }, [isZoomApproved, createZoomClient, getSignature]);

  const beforeUnload = useCallback(() => {
    if (zoomClient) {
      zoomClient.leaveMeeting();
    }
  }, [zoomClient]);
  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnload);
    return () => {
      if (zoomClient) {
        zoomClient.leaveMeeting().then((data) => {});
      }
    };
  }, [zoomClient, beforeUnload]);

  function createZoomClient() {
    const client = ZoomMtgEmbedded.createClient();
    setZoomCLient(client);
  }

  async function startMeeting({ signature, meetingNumber, sdkKey, passWord }) {
    try {
      let meetingSDKElement = document.getElementById("meetingSDKElement");
      zoomClient
        .init({
          debug: true,
          zoomAppRoot: meetingSDKElement,
          language: "pt-BR",
          isSupportAV: true,
          customize: {
            meetingInfo: [
              "topic",
              "host",
              "mn",
              "pwd",
              "telPwd",
              "invite",
              "participant",
              "dc",
              "enctype",
            ],
            toolbar: {
              buttons: [
                {
                  text: "Sair",
                  className: "CustomButton",
                  onClick: () => {
                    window.location.reload();
                  },
                },
              ],
            },
          },
        })
        .then(() => {
          zoomClient
            .join({
              sdkKey,
              signature,
              meetingNumber,
              password: passWord,
              userName: me.name,
              userEmail: me.email || "",
            })
            .then((e) => {
              const currentUser = React.memo(zoomClient.getCurrentUser());
              if (socket) {
                socket.emit("zoom:user:update", {
                  userGuid: me.guid,
                  zoomUserId: currentUser.userId,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
    } catch (error) {}
  }

  async function getSignature() {
    try {
      const { data } = await api.post(`/v1/livestreams/${livestreamGuid}/zoom`);
      startMeeting(data);
    } catch (error) {
      console.error(error);
    }
  }
  function getIconColor() {
    const color = isZoomRejected
      ? "#de1515"
      : isZoomApproved
      ? "#347220"
      : hasZoomRequested || !isStreamming
      ? "#dedede"
      : initialIconColor;
    setIconColor(color);
  }

  return (
    <>
      <button
        onClick={handleApproveZoomRequest}
        disabled={
          hasZoomRequested || !isStreamming || isZoomApproved || isZoomRejected
        }
        className="zoom-icon"
        aria-label="Acesso ao zoom"
      >
        <CameraIcon color={iconColor} />
      </button>
      <ButtonSecondary
        disabled={
          hasZoomRequested || !isStreamming || isZoomApproved || isZoomRejected
        }
        onClick={handleApproveZoomRequest}
        className="zoom-button"
      >
        {isZoomRejected
          ? "Solicitação recusada"
          : isZoomApproved
          ? "Participação Aceita"
          : hasZoomRequested
          ? "Aguarde, já solicitado"
          : "Solicitar acesso ao Zoom"}
      </ButtonSecondary>
    </>
  );
}

export default React.memo(ZoomRequest);
