import api_url from "./api_url";

let socketUrl = api_url

if(window.location.host.includes("assembleias.online")){
    socketUrl = socketUrl.replace("hallo.app", "assembleias.online")
}
// socketUrl = "https://assembleia.api.hallo.app"
export const assemblySocketUrl = socketUrl.replace(".api.", ".socket.") + "/assembly";
export const forumSocketUrl = socketUrl.replace(".api.", "-messenger.socket.") + "/chat";
export const privateChatSocketUrl = api_url.replace(".api.", ".chat.") + "/private-chat/client";

// export const privateChatSocketUrl = "http://localhost:3500/private-chat/client";
// export const forumSocketUrl = "http://localhost:3400/chat";
// export const assemblySocketUrl = "http://localhost:3300/assembly";
