import customer from '~/customer';
import api from '~/services/api';

const localStorageKey = `__hallo_live_token__${customer}`;

export function getToken() {
  return window.localStorage.getItem(localStorageKey);
}

export function setToken(token) {
  api.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
  };

  window.localStorage.setItem(localStorageKey, token);
}

export function removeToken() {
  window.localStorage.removeItem(localStorageKey);
}
