import React from "react";
import playIcon from "~/assets/imgs/common/play.svg";

export default function Play(props) {
    const { handleClick } = props;

    return (
        <button className="player__button" onClick={() => handleClick()}>
            <img src={playIcon} alt="Play" />
        </button>
    );
}
