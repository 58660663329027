export function openModal(content, backdropCallback = null) {
    return {
        type: "@modal/OPEN_MODAL",
        payload: {
            content,
            backdropCallback
        },
    };
}

export function closeModal(closeCallback) {
    return {
        type: "@modal/CLOSE_MODAL",
        payload: {
            closeCallback,
        },
    };
}

export function resetModalState() {
    return {
        type: "@modal/RESET_MODAL_STATE"
    }
}