import React from "react";

import { useSelector, useDispatch } from "react-redux";

import * as ModalActions from "~/store/modules/modal/actions";

import "./style.scss";

export default () => {
  const dispatch = useDispatch();
  const modalState = useSelector((store) => store.modal);

  const resetModalState = () => {
    if (modalState.backdropCallback) {
      modalState.backdropCallback();
    }
    dispatch(ModalActions.resetModalState());
  };

  const handleKeyDown = (event) => {
    //27 is keyCode for ESC
    if (event.keyCode == 27) {
      resetModalState();
      return;
    }
  };

  if (!modalState.visible) {
    return null;
  }

  return (
    <div
      className="modal-backdrop"
      onClick={resetModalState}
      onKeyDown={handleKeyDown}
      tabIndex="-1"
    >
      <div className="modal-card" onClick={(e) => e.stopPropagation()}>
        {modalState.content}
      </div>
    </div>
  );
};
