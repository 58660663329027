import React from "react";

import parse from "html-react-parser";

import "./header.styles.scss";

import Logo from "./Logo";

const Header = ({ content }) => {
  return (
    <>
      <header>
        <div className="container-logo">
          <div className="logo">
            <Logo />
          </div>
        </div>
      </header>
      {content && (
        <div className="content-text">
          <div>{parse(content)}</div>
        </div>
      )}
    </>
  );
};

export default Header;
