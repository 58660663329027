import produce from "immer";

const INITIAL_STATE = {
    livestream: {}
};

export default function livestream(state = INITIAL_STATE, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case "@livestream/livestream": {
                draft.livestream = action.payload.livestream;
                break;
            }
            default:
        }
    });
}
