import React from "react";
import pauseIcon from "~/assets/imgs/common/pause-button.svg";

export default function Play(props) {
    const { handleClick } = props;

    return (
        <button className="player__button" onClick={() => handleClick()}>
            <img src={pauseIcon} alt="Pausar" />
        </button>
    );
}
