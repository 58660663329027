import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import iconDownload from "../../../assets/landingpage/unicred/download.png";
import iconInstagram from "../../../assets/landingpage/unicred/instagram.png";
import iconFacebook from "../../../assets/landingpage/unicred/facebook.png";
import iconTwitter from "../../../assets/landingpage/unicred/twitter.png";
import iconYoutube from "../../../assets/landingpage/unicred/youtube.png";
import iconLinkedin from "../../../assets/landingpage/unicred/linkedin.png";
import iconWhatsapp from "../../../assets/landingpage/unicred/whatsapp.png";
import unicredLogo from "../../../assets/landingpage/unicred/unicred.png";

import "./styles.scss";

function Unicred({ domain }) {
  useEffect(() => {
    ReactGA.send({
      hitType: "event",
      eventCategory: "landingPage",
      eventAction: "access",
      eventLabel: domain,
    });
  }, []);

  return (
    <div className="lp-unicred">
      <div className="section-main">
        <div className="section-main-container wrap">
          <div className="section-main-info">
            <h1>
              Pré-Assembleia Geral Ordinária da
              <span>Unicred</span>
              <span>Valor Capital</span>
            </h1>

            <p>
              Que bom que você chegou!<br />
              Sua presença e voto são fundamentais para definirmos o futuro da nossa cooperativa. Venha conosco, pois a Pré Assembleia Geral Ordinária da Unicred Valor Capital já vai começar!
            </p>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo">
        <div className="section-2-container section-2-container--align-center wrap">
          <div className="section-2-info section-2-info-unicred-vale">
            {/* <h1 className="title-new">Nosso presidente tem uma <span>mensagem especial para você</span></h1> */}
            <p>Confira a mensagem do Presidente do Conselho de Administração, Dr. Carlos Gilberto Crippa.</p>
          </div>

          <div className="section-2-video">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-valor-capital-dr-crippa.m4v" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo" style={{ backgroundColor: "#d6d1ca" }}>
        <div className="section-2-container section-2-container--align-center wrap">
          <div className="section-2-video-left">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-valor-capital-realizacoes.m4v" />
              </video>
            </div>
          </div>

          <div className="section-2-info section-2-info-unicred-vale">
            {/* <h1 className="title-new">Clique para ver as <span>principais ações de 2023</span></h1> */}
            <p style={{ color: "#2f2b28" }}>2023 foi um ano especial, ano que a Unicred Valor Capital comemorou seu 30º aniversário. Um ano de muitas conquistas, mas também de muitos desafios. Clique e veja abaixo algumas das realizações da nossa cooperativa.</p>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo">
        <div className="section-2-container section-2-container--align-center wrap">
          <div className="section-2-info section-2-info-unicred-vale">
            {/* <h1 className="title-new">Veja em detalhes as<span>demonstrações contábeis</span></h1> */}
            <p>Chegou a hora de apresentarmos os resultados da cooperativa no exercício de 2023. Clique no vídeo e confira.</p>
          </div>

          <div className="section-2-video">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-valor-capital-saldanha.m4v" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo" style={{ backgroundColor: "#d6d1ca" }}>
        <div className="section-2-container section-2-container--align-center wrap">
          <div className="section-2-video-left">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-valor-capital-projeto-somar.m4v" />
              </video>
            </div>
          </div>

          <div className="section-2-info section-2-info-unicred-vale">
            {/* <h1 className="title-new">Estamos prontos para um <span>futuro cada vez melhor</span></h1> */}
            <p style={{ color: "#2f2b28" }}>As ações sociais promovidas pelo cooperativismo junto ao Projeto Somar também foram destaque em 2023. Conheça as realizações do projeto.</p>
          </div>
        </div>
      </div>

      <div className="section-form" style={{ backgroundColor: "#332f2a" }}>
        <div className="wrap">
          <div className="section-agencies">
            <p>A Assembleia Geral Ordinária da Unicred Valor Capital, acontecerá no dia 23/04/24, às 19 horas, no formato híbrido.</p>
            <p>Você poderá assistir e votar pelo site, pelo aplicativo Unicred Mobile clicando aqui, ou se preferir, presencialmente em uma das agências citadas abaixo.</p>

            <ul>
              <li>
                <h4>Agência Beira Mar</h4>
                R Bocaiuva, 2468 - Beiramar Shopping, Piso L3 - Centro,<br />
                Florianópolis - Santa Catarina - CEP: 88015-530
              </li>

              <li>
                <h4>Agência Biguaçu</h4>
                Rua Avanir Maria Freiberger, 515 - Centro,<br />
                Biguaçu - Santa Catarina - CEP: 88160-148
              </li>

              <li>
                <h4>Agência Tenente Silveira</h4>
                Rua Tenente Silveira, 315 - Centro,<br />
                Florianópolis - Santa Catarina - CEP: 88010-301
              </li>

              <li>
                <h4>Agência Estreito</h4>
                Rua Santo Saraiva, 146 - Estreito,<br />
                Florianópolis - Santa Catarina - CEP: 88070-101
              </li>

              <li>
                <h4>Agência Foz do Iguaçu</h4>
                Rua Marechal Floriano Peixoto, 960 - Centro,<br />
                Foz do Iguaçu - Paraná - CEP: 88010-250
              </li>

              <li>
                <h4>Agência Gama D'eça</h4>
                Avenida Prefeito Osmar Cunha, 520, Térreo e 1° andar - Centro,<br />
                Florianópolis - Santa Catarina - CEP: 88015-100
              </li>

              <li>
                <h4>Agência Garopaba</h4>
                Rua Ismael Lobo, 45 - Centro,<br />
                Garopaba - Santa Catarina - CEP: 88495-000
              </li>

              <li>
                <h4>Agência Ingleses</h4>
                Rodovia Armando Calil Bulos, N° 6540 - SC 403 - loja 101, bloco II, Portal dos Ingleses - Ingleses Norte,<br />
                Florianópolis - Santa Catarina - CEP: 88058-001
              </li>

              <li>
                <h4>Agência Jairo Ramos</h4>
                Rua dos Otonis, nº 742/738 - Vila Clementino,<br />
                São Paulo - SP - CEP: 04025002
              </li>

              <li>
                <h4>Agência Madre Benvenuta</h4>
                Avenida Madre Benvenuta, 1273 - Santa M,<br />
                Florianópolis - Santa Catarina - CEP: 88035-000
              </li>

              <li>
                <h4>Agência Medianeira</h4>
                Rua João XXIII, 1874 - Centro,<br />
                Medianeira - PR - CEP: 85884000
              </li>

              <li>
                <h4>Agência Palhoça</h4>
                Rua 24 De Abril, 2977 - Centro,<br />
                Palhoça - Santa Catarina - CEP: 88131-030
              </li>

              <li>
                <h4>Agência Sul da Ilha</h4>
                Rod Francisco Magno Vieira, 1408 - Campeche,<br />
                Florianópolis - Santa Catarina - CEP: 88065-000
              </li>

              <li>
                <h4>Agência São José</h4>
                Avenida Lédio João Martins, 125 - Kobrasol,<br />
                São José - Santa Catarina - CEP: 88101-080
              </li>

              <li>
                <h4>Agência Tijucas</h4>
                Rua Leoberto Leal, 100 - Centro,<br />
                Tijucas - Santa Catarina - CEP: 88200-000
              </li>
            </ul>

            <p>
              Sua presença e voto são fundamentais para as decisões da cooperativa.<br />
              Participe conosco, esperamos você!
            </p>
            <p style={{ color: '#998543' }}>#futurodevalor #valorcapital</p>
          </div>
        </div>
      </div>

      <div className="section-links" style={{ backgroundColor: "#3d3935" }}>
        <div className="section-links-container wrap">
          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-valor-capital-edital-2024.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Edital
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-valor-capital-parecer-conselho-fiscal.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Parecer do Conselho Fiscal
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-valor-capital-relatorio-2023.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Relatório de Gestão
                </a>
              </li>
            </ul>
          </div>

          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2023/manual-unicred-web-2023.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Tutorial de acesso computador
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2023/manual-unicred-mobile-2023.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Tutorial de acesso celular
                </a>
              </li>
            </ul>
          </div>

          <div className="section-links-info">
            <h1>Ficou com alguma dúvida?</h1>
            <p>Converse com o seu Gerente de Relacionamento ou envie sua dúvida para o e-mail <a href="mailto:marketing.florianopolis@unicred.com.br">marketing.florianopolis@unicred.com.br</a> ou WhatsApp <span><img src={iconWhatsapp} /></span> <a href="https://wa.me/5548996238954" target="_blank">(48) 9 9623-8954</a>.</p>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer-container wrap">
          <div className="footer-info">
            <a className="footer-info-site" href="https://unicred.com.br" target="_blank">unicred.com.br</a>

            <div className="footer-info-box">
              <h2 style={{ marginBottom: 0 }}>Central de Relacionamento Unicred</h2>
              <h2>24 horas por dia, 7 dias por semana</h2>
              <p>3003 7703 (Capitais e Regiões Metropolitanas)</p>
              <p>0800 200 7302 (Demais Localidades)</p>
              <p>+55 800 200 7302 (WhatsApp)</p>
              <p>+55 11 3003 7703 (Ligações do Exterior)</p>
              <p>Chat no app Unicred Mobile e Internet Banking</p>
            </div>

            <div className="footer-info-box">
              <h2>SAC</h2>
              <p>0800 647 2930</p>
            </div>

            <div className="footer-info-box">
              <h2>Ouvidoria</h2>
              <p>0800 940 0602</p>
            </div>
          </div>

          <div className="footer-social">
            <div className="footer-social-box">
              <h2>Siga a Unicred nas redes sociais</h2>
              <ul className="social-list">
                <li><a href="https://www.instagram.com/unicredbrasil/" title="Instagram" target="_blank"><img src={iconInstagram} alt="Instagram" /></a></li>
                <li><a href="https://www.facebook.com/unicredbrasil" title="Facebook" target="_blank"><img src={iconFacebook} alt="Facebook" /></a></li>
                <li><a href="https://twitter.com/unicredbrasil" title="Twitter" target="_blank"><img src={iconTwitter} alt="Twitter" /></a></li>
                <li><a href="https://www.youtube.com/channel/UCdowVMAIRENRC2VH0_gcdww" title="Youtube" target="_blank"><img src={iconYoutube} alt="Youtube" /></a></li>
                <li><a href="https://br.linkedin.com/company/unicredbr" title="Linkedin" target="_blank"><img src={iconLinkedin} alt="Linkedin" /></a></li>
              </ul>
            </div>

            <div className="footer-logo">
              <img src={unicredLogo} alt="Unicred" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unicred;
