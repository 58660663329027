import produce from "immer"

const INITIAL_STATE = {
  open: false,
  mouseLeavesWindow: false
}

export default function banner(state = INITIAL_STATE, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case "@banner/OPEN_BANNER": {
                draft.open = action.payload.open;
                break;
            }
            case "@banner/MOUSE_LEAVES_WINDOW": {
                draft.mouseLeavesWindow = action.payload.mouseLeavesWindow;
                break;
            }
            default:
        }
    });
}
