import React, { useState, useEffect } from "react";

import Header from "./Header";
import Form from "./Form";
import Redirect from "./Redirect";
import Loading from "~/components/Loading";

import api from "~/services/api";

import { getCurrentDomain } from "~/utils";

import "./delegates.styles.scss";

function Delegates() {
  const [isLoading, setIsLoading] = useState(true);

  const [delegate, setDelegate] = useState({});

  useEffect(() => {
    async function fetchDelegateContent() {
      try {
        const currentDomain = getCurrentDomain();

        const { data } = await api.get(`v1/delegates/content/${currentDomain}`);

        setDelegate(data);
      } catch (error) {
        console.error("Error to fetch delegate content: ", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchDelegateContent();
  }, []);

  return (
    <>
      {!isLoading ? (
        <div className="container-delegates">
          <Header content={delegate?.delegateContent?.content} />
          {delegate.delegateContent && delegate.delegateContent.enabled ? (
            <Form cooperatives={delegate.cooperatives} />
          ) : (
            <Redirect />
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Delegates;
