import React, {
  useEffect,
  useState,
  Suspense,
  lazy,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Loading from "~/components/Loading";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";

import config from "../../../../config";
import * as ChatActions from "~/store/modules/chat/actions";
import axios from "axios";
import api from "~/services/api";
import { forumSocketUrl } from "~/services/socket_url";
import "./Forum.scss";
import CommentsHeader from "./CommentsHeader/CommentsHeader";
import ReplyComment from "./ReplyComment/ReplyComment";
import PinnedComment from "./Comments/Comment/PinnedComment";

const CommentForm = lazy(() => import("./ForumForm/ForumForm"));
const Comments = lazy(() => import("./Comments/Comments"));

function Forum({ livestreamGuid, livestream, liveFinished, meFinished }) {
  const dispatch = useDispatch();

  const [loadingMessages, setLoadingMessages] = useState(true);
  const [messages, setMessages] = useState([]);
  const [comment, setComment] = useState({
    message: "",
    attachment_type: "",
    attachment: "",
    anonymous: false,
    direct: !config.allowPublicMessages && config.allowPrivateMessages,
  });
  const [socket, setSocket] = useState(null);

  const [allowForum, setAllowForum] = useState(true);
  const [allowForumVoice, setAllowForumVoice] = useState(false);
  const [allowPublicChat, setAllowPublicChat] = useState(true);
  const [allowPrivateChat, setAllowPrivateChat] = useState(true);
  const [allowReplyMessage, setAllowReplyMessage] = useState(true);
  const [pinnedMessageGuid, setPinnedMessageGuid] = useState(null);
  const [pinnedMessage, setPinnedMessage] = useState(null);
  const [repliedMessage, setRepliedMessage] = useState(null);
  const [countNewMessages, setCountNewMessages] = useState(0);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [atBottom, setAtBottom] = useState(true);
  const me = useSelector((store) => store.me.me);
  const repliedRef = useRef(null);
  const bottomRef = useRef(null);
  useEffect(() => {
    if (meFinished) {
      const socket = io(forumSocketUrl, {
        auth: {
          token: me.socket_token,
        },
        query: {
          assemblyGuid: livestreamGuid,
        },
        withCredentials: true,
      });

      setSocket(socket);

      socket.on("initial_messages", (data) => {
        setLoadingMessages(false);

        data?.messages && setMessages(data.messages);

        socket.on("initial_pinned_message", (messageGuid) => {
          if (messageGuid) {
            setPinnedMessageGuid(messageGuid);
          }
        });
      });

      socket.on("messages", (data) => {
        if (data.messages.length > 0) {
          setMessages((messages) => [...messages, ...data.messages]);
        } else {
          setHasMoreMessages(false);
        }
      });

      socket.on("message", (newMessage) => {
        handleNewMessage(newMessage);
      });

      socket.on("delete_message_success", (messageGuid) => {
        if (messageGuid) {
          setMessages((messages) => {
            return messages.filter((message) => message.guid !== messageGuid);
          });
        }
      });

      socket.on("pinned_message_success", (messageGuid) => {
        if (messageGuid) {
          setPinnedMessageGuid(messageGuid);
        }
      });

      socket.on("delete_pinned_message_success", (messageGuid) => {
        if (messageGuid) {
          setPinnedMessageGuid(null);
          setPinnedMessage(null);
        }
      });

      socket.on("chat_config_changed", (data) => {
        setAllowForum(data.allow_forum);
        setAllowForumVoice(data.allow_forum_voice);
        setAllowPublicChat(data.allow_public_chat);
        setAllowPrivateChat(data.allow_private_chat);
        dispatch(ChatActions.allowPrivateChat(data.allow_private_chat));
        setAllowReplyMessage(data.allow_reply_message);
        if (!data.allow_reply_message) {
          setRepliedMessage(null);
        }
        setComment({
          ...comment,
          direct:
            (!config.allowPublicMessages && config.allowPrivateMessages) ||
            !data.allow_public_chat,
        });
      });
    }
  }, [meFinished]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (livestream) {
      setAllowForum(livestream.allow_forum);
      setAllowForumVoice(livestream.allow_forum_voice);
      setAllowPublicChat(livestream.allow_public_chat);
      setAllowPrivateChat(livestream.allow_private_chat);
      dispatch(ChatActions.allowPrivateChat(livestream.allow_private_chat));
      setAllowReplyMessage(livestream.allow_reply_message);

      setComment({
        ...comment,
        direct:
          (!config.allowPublicMessages && config.allowPrivateMessages) ||
          !livestream.allow_public_chat,
      });
    }
  }, [livestream]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pinnedMessageGuid) {
      const message = messages.find(
        (message) => message.guid === pinnedMessageGuid
      );
      if (message) {
        setPinnedMessage(message);
      }
    }
  }, [pinnedMessageGuid]);

  useEffect(() => {
    if (repliedMessage && repliedRef) {
      repliedRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }

    return () => {};
  }, [repliedMessage]);

  async function handleSubmitComment(event, message) {
    if (event) {
      event.preventDefault();
    }
    const { direct } = comment;

    if (message && message.attachment_type === "mp3") {
      let send = {
        ...message,
      };
      if (repliedMessage) {
        send = {
          ...send,
          reply_to: {
            ...repliedMessage,
          },
        };
      }
      socket.emit("message", send);
      clearMessages();
      return;
    }
    if (!comment.message.trim()) {
      setComment((prev) => ({
        ...prev,
        message: "",
      }));
      return;
    }
    if (direct) {
      dispatch(ChatActions.renderIcon(true));
      dispatch(ChatActions.chatModal(true));
      dispatch(ChatActions.messageToSend(comment.message));
    } else {
      const bodyRequest = {
        ...comment,
      };

      if (repliedMessage) {
        bodyRequest.reply_to = {
          ...repliedMessage,
        };
      }

      socket.emit("message", {
        ...bodyRequest,
      });
    }
    clearMessages();
  }

  function clearMessages() {
    setRepliedMessage(null);
    setComment({
      message: "",
      anonymous: false,
      direct:
        (!config.allowPublicMessages && config.allowPrivateMessages) ||
        !allowPublicChat,
    });
  }

  async function handleInputChange(event, audio) {
    if (event === "audio" && audio) {
      try {
        const result = await api.get(
          `/v1/file-upload?context=documents&extension=mp3`
        );
        await axios.create().put(result.data.url, audio);
        const document_url = result.data.url.split("?")[0];

        let data = { ...comment };
        data = {
          ...data,
          message: "",
          attachment: document_url,
          attachment_type: "mp3",
        };
        setComment(data);
        handleSubmitComment(false, data);
      } catch (e) {
        console.log(e);
      }
    } else {
      const { name, value, type, checked } = event.target;
      const newValue = type === "checkbox" ? checked : value;

      if (type === "checkbox" && checked) {
        let second_option = name === "direct" ? "anonymous" : "direct";

        setComment({
          ...comment,
          [name]: newValue,
          [second_option]: !newValue,
        });
      } else {
        setComment({
          ...comment,
          [name]: newValue,
        });
      }
    }
  }

  function handleNewMessage(newMessage) {
    setMessages((current) => {
      return [newMessage, ...current];
    });

    if (newMessage.from?.guid === me.guid) {
      setAtBottom(true);
    } else {
      setCountNewMessages((count) => count + 1);
    }
  }

  function audioMessageHandler(seconds) {
    console.log(seconds);
  }

  const componentHref = useCallback((href, text, key)=> {
    function handleClick() {
      if (
        window.confirm(
          "Você será redirecionado para outra página. Deseja continuar?"
        )
      ) {
        window.open(href, "_blank");
      }
    }

    return (
      <a key={key} onClick={handleClick}>
        {text}
      </a>
    );
  },[])


  return (
    <div className="container-comments">
      <CommentsHeader socket={socket} />
      <div className="comments-content">
        {loadingMessages ? (
          <div className="loading-ul">
            <Loading size={2} />
          </div>
        ) : (
          <Suspense fallback={<div />}>
            {/* {pinnedMessage && <PinnedComment pinnedComment={pinnedMessage} componentHref={componentHref}/>} */}
            <Comments
              socket={socket}
              data={messages}
              setRepliedMessage={setRepliedMessage}
              repliedRef={repliedRef}
              bottomRef={bottomRef}
              countNewMessages={countNewMessages}
              setCountNewMessages={setCountNewMessages}
              hasMoreMessages={hasMoreMessages}
              atBottom={atBottom}
              setAtBottom={setAtBottom}
              forumForGuests={livestream.can_comment}
              allowPublicChat={allowForum && allowPublicChat}
              allowReplyMessage={allowReplyMessage}
              pinnedMessage={pinnedMessage}
              componentHref={componentHref}
            />
          </Suspense>
        )}
        {repliedMessage &&
          allowForum &&
          allowPublicChat &&
          allowReplyMessage && (
            <ReplyComment
              repliedMessage={repliedMessage}
              setRepliedMessage={setRepliedMessage}
              repliedRef={repliedRef}
            />
          )}
        {!liveFinished &&
          allowForum &&
          (!me.is_guest || livestream.can_comment) && (
            <Suspense fallback={<div />}>
              <CommentForm
                comment={comment}
                live_finished={liveFinished}
                allowForumVoice={allowForumVoice}
                handleSubmitComment={handleSubmitComment}
                handleInputChange={handleInputChange}
                allowPublicChat={allowPublicChat}
                allowPrivateChat={allowPrivateChat}
                recording={(seconds) => audioMessageHandler(seconds)}
              />
            </Suspense>
          )}
      </div>
    </div>
  );
}

export default Forum;
