import logo from "../assets/imgs/ailos/Logo.svg";
import logo_white from "../assets/imgs/ailos/Logo.svg";

export default {
  logo,
  logo_white,
  name: "Hallo",
  title: "Hallo Assembleias",
  appcode: 2,
  captchaSiteKey: "6LebO8IhAAAAAApCTVQlIKAmnnP2suTrTsWAuAUk",
  facebookAppId: "261213698471647",
  googleAppId:
    "820782713227-lp4uepgj2066jnp9e6ca76p59gr1t2a5.apps.googleusercontent.com",
  googleTrackingId: "G-J2CRKSSP2R",
  showPA: true,
  showGroup: true,
  allowPublicMessages: true,
  allowPrivateMessages: true,
  enableGuestAccess: true,
  showMyLivestreamsOnly: false,
  showMyLivestreamsByAgencyAndGroup: true,
  prePollTitle: "Assembleias Antecipadas",
  showBallotPaper: true,
  prePollText: "Assembleia Gravada",
  posPollText: "Assembleia Gravada",
};
