import React, { useEffect, useState } from "react";

import {
  Switch,
  Route,
  useLocation,
  Redirect,
  useHistory,
} from "react-router-dom";

import ReactGA from "react-ga4";

import Home from "~/pages/Home";
import MyAccount from "~/pages/MyAccount";
import Livestream from "~/pages/Livestream";
import Authentication from "~/pages/Authentication";
import Landingpage from "~/pages/LandingPage/Landingpage";
import Delegates from "~/pages/Delegates";

import definition from "~/config";
import api from "~/services/api";
import { getCurrentDomain } from "~/utils";

export default function Routes() {
  const location = useLocation();
  const history = useHistory();
  const [applyRoute, setApplyRoute] = useState(false);
  const [landingPageInfo, setLandingPageInfo] = useState({});
  const [loading, setLoading] = useState(true);

  ReactGA.initialize(definition.googleTrackingId);

  useEffect(() => {
    ReactGA.set({ page: location.pathname });

    getPath(getCurrentDomain());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getPath(domain) {
    try {
      const { data } = await api.get(`/v1/landing-page/${domain}`);

      const ends_at = new Date(data.ends_at);
      const now = new Date();
      if (ends_at > now) {
        setLandingPageInfo(data);
        setApplyRoute(true);
        history.push("/lp");
      } else {
        setApplyRoute(false);
      }
    } catch (error) {
      setApplyRoute(false);
    } finally {
      setLoading(false);
    }
  }
  return loading ? (
    <div />
  ) : applyRoute ? (
    <Switch>
      <Route
        exact
        path="/lp"
        component={() => <Landingpage info={landingPageInfo} />}
      />
      <Route path="*">
        <Redirect to="/lp" />
      </Route>
    </Switch>
  ) : (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/lives/:guid" component={Livestream} />
      <Route path="/my-account" component={MyAccount} />
      <Route path="/acessar/:token" component={Authentication} />
      <Route
        exact
        path="/admin"
        component={() => {
          window.location.href = "/admin/";
          return null;
        }}
      />
      <Route exact path="/delegados" component={Delegates} />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  )
}
