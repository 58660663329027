import { combineReducers } from "redux";

import me from "./me/reducer.js";
import livestream from "./livestream/reducer.js";
import chat from "./chat/reducer.js";
import user_rules from "./user_rules/reducer.js";
import configs from "./configs/reducer.js";
import modal from "./modal/reducer";
import banner from "./banner/reducer";

export default combineReducers({ chat, me, user_rules, livestream, configs, modal, banner });
