import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import * as ChatActions from "~/store/modules/chat/actions";
import Loading from "~/components/Loading";
import Input from "~/components/Input/Input";

export default function Messages({ socket }) {
  const scroll = useRef(null);
  const dispatch = useDispatch();
  const messageToSendChat = useSelector((store) => store.chat.message_to_send);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    socket.emit("initial_messages:pull");

    socket.on("initial_messages:push", (data) => {
      setMessages(data.messages);
      setLoading(false);
      scrollDown();
    });

    socket.on("messages:receive", (data) => {
      setMessages((currentMessages) => {
        return [...currentMessages, data.message];
      });
    });

    return () => {
      socket.emit("leave");
      socket.removeAllListeners("initial_messages:push");
      socket.removeAllListeners("messages:receive");
    }
  }, []);

  useEffect(() => {
    if (messageToSendChat && !loading) {
      socket.emit("messages:send", {
        message: messageToSendChat
      });
      dispatch(ChatActions.messageToSend(null));
    }
  }, [messageToSendChat, loading]);

  useEffect(() => {
    scrollDown();
  }, [messages]);

  function scrollDown() {
    scroll.current && scroll.current.scrollIntoView(false);
  }

  function handleSubmitMessage(event) {
    event.preventDefault();

    if (message.trim()) {
      socket.emit("messages:send", {
        message
      });
      setMessage("");
    }
  }

  function renderTime(currentMessage, previousMessage) {
    const currentTimeMessage = moment(currentMessage);
    const previousTimeMessage = moment(previousMessage.created_at);

    if (currentTimeMessage.diff(previousTimeMessage, "days") >= 1) {
      return (
        <div className="span-hour">
          <span>{moment(currentMessage).calendar()}</span>
        </div>
      );
    }
  }

  return (
    <Fragment>
      <div className="container-chat-box">
        <div className="body-chat-box">
          {loading ? (
            <Loading size={3} />
          ) : (
            <Fragment>
              {messages.length > 0 && (
                <div className="span-hour">
                  <span>{moment(messages[0].created_at).calendar()}</span>
                </div>
              )}
              {messages.map((message, index, arr) => (
                <Fragment key={message.guid}>
                  {index === 0 ? null : renderTime(message.created_at, arr[index - 1])}

                  <ul className={message.is_admin ? "received-messages" : "sent-messages"}>
                    <li>
                      <p>{message.message}</p>
                    </li>
                  </ul>
                </Fragment>
              ))}
            </Fragment>
          )}
          <div ref={scroll} />
        </div>
        <div className="footer-chat-box">
          <form onSubmit={handleSubmitMessage}>
            <div>
              <Input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Digite uma mensagem"
              />
              <div>
                <button
                  type="submit"
                  className="icon-ic_mensagem"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
