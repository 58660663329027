export function parsePreviewUrl(file) {
  let url = file;
  if (file instanceof File) url = URL.createObjectURL(file);

  return url;
}

export function getFileType(fileName) {
  const array = fileName.split(".");
  return array[array.length - 1];
}

export function getCurrentDomain() {
  return window.location.host.split(".")[0];
}

export function removeCharactersExceptNumbers(value) {
  const parsedValue = String(value).replace(/\D/g, "");

  return parsedValue;
}
