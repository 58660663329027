import React from "react";
import { useController } from "react-hook-form";

import ReactSelect from "react-select";

import "./select.styles.scss";

function Select({ label, name, control, options, error, ...rest }) {
  const {
    field: { onChange, value: selectedValue, ...fieldProps },
  } = useController({
    name,
    control,
  });

  return (
    <div className={`container-input ${error && "with-error"}`}>
      <label htmlFor={name}>{label}</label>
      <ReactSelect
        {...rest}
        {...fieldProps}
        className="react-select"
        components={{
          IndicatorSeparator: () => null,
        }}
        options={options}
        onChange={({ value }) => {
          onChange(value);
        }}
        value={options.find(({ value }) => value === selectedValue)}
      />

      {error && <div className="error">{error.message}</div>}
    </div>
  );
}

export { Select };
