import React, { forwardRef } from "react";

import "./checkbox.styles.scss";

const Checkbox = forwardRef(({ label, name, error, ...rest }, ref) => {
  return (
    <>
      <div className="container-checkbox">
        <input type="checkbox" ref={ref} name={name} {...rest} />
        <label htmlFor={name}>{label}</label>
      </div>
      {error && <div className="error">{error.message}</div>}
    </>
  );
});

export { Checkbox };
