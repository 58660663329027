import moment from "moment";

import noPhoto from "~/assets/imgs/common/noPhoto.jpeg";

export function onErrorImage(e, style) {
  let styleImage;
  if (style) styleImage = style;
  else styleImage = "height: 100%;";

  e.target.src = noPhoto;
  e.target.style = styleImage;
}

export function formatDateHour(date) {
  return moment(date).format("HH:mm");
}
export function formatDuration(duration) {
  if (!duration) return;
  return moment(duration, "ss").format("mm:ss");
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function formatDocument(data) {
  if (!data) {
      return null
  }

  const cnpjCpf = data.replace(/\D/g, "")

  let label = 'Documento'
  let value = cnpjCpf

  if (cnpjCpf.length === 14) {
      label = 'CNPJ'
      value = cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")
  }

  if (cnpjCpf.length === 11) {
      label = 'CPF'
      value = cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")
  }

  return {
    label,
    value
  }
}

export function validateCPF_CNPJ(entry) {
  entry = entry.replace(/\D/g, "");

  if (/^(\d)\1+$/.test(entry)) return { valid: false, type: "CPF/CNPJ" };

  if (entry.length === 11) {
    let sum = 0;
    let rest;
    for (let i = 1; i <= 9; i++)
      sum = sum + parseInt(entry.substring(i - 1, i), 10) * (11 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(entry.substring(9, 10), 10)) return { valid: false, type: "CPF" };

    sum = 0;
    for (let i = 1; i <= 10; i++)
      sum = sum + parseInt(entry.substring(i - 1, i), 10) * (12 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(entry.substring(10, 11), 10)) return { valid: false, type: "CPF" };

    return { valid: true, type: "CPF" };
  } else if (entry.length === 14) {
    let size = 0;
    let nums = 0;
    size = entry.length - 2;
    nums = entry.substring(0, size);
    let digits = entry
      .substring(size)
      .split("")
      .map(d => parseInt(d, 10));
    let sum = 0;
    let pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += nums.charAt(size - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== digits[0]) return { valid: false, type: "CNPJ" };

    size = size + 1;
    nums = entry.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += nums.charAt(size - i) * pos--;
      if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== digits[1]) return { valid: false, type: "CNPJ" };

    return { valid: true, type: "CNPJ" };
  }

  return { valid: false, type: "CPF/CNPJ" };
}
