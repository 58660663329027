import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import iconDownload from "../../../assets/landingpage/unicred/download.png";
import iconInstagram from "../../../assets/landingpage/unicred/instagram.png";
import iconFacebook from "../../../assets/landingpage/unicred/facebook.png";
import iconTwitter from "../../../assets/landingpage/unicred/twitter.png";
import iconYoutube from "../../../assets/landingpage/unicred/youtube.png";
import iconLinkedin from "../../../assets/landingpage/unicred/linkedin.png";
import unicredLogo from "../../../assets/landingpage/unicred/unicred.png";

import unicredSelo from "../../../assets/landingpage/unicred/centro-sul/selo-somoscoop.png";
import grafico1 from "../../../assets/landingpage/unicred/centro-sul/grafico-unicred-centro-sul-1.png";
import grafico2 from "../../../assets/landingpage/unicred/centro-sul/grafico-unicred-centro-sul-2.png";
import grafico3 from "../../../assets/landingpage/unicred/centro-sul/grafico-unicred-centro-sul-3.png";
import grafico4 from "../../../assets/landingpage/unicred/centro-sul/grafico-unicred-centro-sul-4.png";
import grafico5 from "../../../assets/landingpage/unicred/centro-sul/grafico-unicred-centro-sul-5.png";
import grafico6 from "../../../assets/landingpage/unicred/centro-sul/grafico-unicred-centro-sul-6.png";

import seloFitch from "../../../assets/landingpage/unicred/centro-sul/selo-fitch.png";
import seloMoodys from "../../../assets/landingpage/unicred/centro-sul/selo-moodys.png";
import seloSomoscoop from "../../../assets/landingpage/unicred/centro-sul/selo-somoscoop.png";

import "./styles.scss";

function UnicredCentroSul({ domain }) {
  useEffect(() => {
    ReactGA.send({
      hitType: "event",
      eventCategory: "landingPage",
      eventAction: "access",
      eventLabel: domain,
    });
  }, []);

  return (
    <div className="lp-unicred">
      <div className="section-main">
        <div className="section-main-container wrap">
          <div className="section-main-info section-main-info--custom">
            <h1>
              <p>A Unicred Centro Sul</p> convida você para
              <span>a Assembleia Geral</span>
              <span>Extraordinária e</span>
              <span>Ordinária Digital!</span>
            </h1>

            {/* <p>
              Cada vez melhor e mais forte, mesmo com incertezas no cenário macroeconômico, a Unicred Centro Sul cresceu e apresentou em 2023 excelentes resultados nos principais indicadores de saúde financeira.
            </p> */}
          </div>
        </div>
      </div>

      <div className="section-centro-sul-videos">
        <div className="wrap">
          <h1>Entregas da Central Conexão e Centro-Sul 2023</h1>

          <div className="section-centro-sul-videos-container">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-central-sul-conexao.m4v" />
              </video>
            </div>

            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-centro-sul-ago-2024.m4v" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="section-form" style={{ backgroundColor: "#332f2a" }}>
        <div className="wrap">
          <div className="section-form-info">
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Cada vez melhor e mais forte, mesmo com incertezas no cenário macroeconômico, a Unicred Centro Sul cresceu e apresentou em 2023 excelentes resultados nos principais indicadores de saúde financeira.
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Eles traduzem solidez, credibilidade e, acima de tudo, renovam o compromisso da cooperativa com uma gestão responsável e eficiente.
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Para nós, excelência é isso: fazer bem-feito para merecer a confiança dos cooperados.
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Os números a seguir resumem a nossa performance em 2023.
            </p>
          </div>
        </div>
      </div>

      <div className="section-graphic" style={{ backgroundColor: "#fff" }}>
        <div className="section-graphic-container">
          <img src={grafico1} alt="" />
          <img src={grafico2} alt="" />
          <img src={grafico3} alt="" />
          <img src={grafico4} alt="" />
          <img src={grafico5} alt="" />
          <img src={grafico6} alt="" />
        </div>
      </div>

      <div className="section-awards">
        <div className="section-awards-container wrap">
          <div className="section-awards-left">
            <p>
              Centro-Sul TOP 5 – entre as melhores do sistema Unicred e a 2ª maior em volume de ativos do Sistema Unicred
            </p>

            <ul>
              <li>+ de 3 Bilhões em recursos administrados</li>
              <li>+ de 20 mil sócios</li>
              <li>+ de 1 bilhão em carteira de crédito</li>
              <li>+ de 400 milhões em patrimônio Precaver</li>
            </ul>
          </div>

          <div className="section-awards-right">
            <img src={seloMoodys} alt="" />
            <img src={seloFitch} alt="" />
            <img src={seloSomoscoop} alt="" />
          </div>
        </div>
      </div>

      <div className="section-form" style={{ backgroundColor: "#332f2a" }}>
        <div className="wrap">
          <div className="section-form-info">
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Participe da Assembleia Geral Ordinária da Unicred Centro Sul!
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Data: 02 de abril de 2024<br />
              Horário: 19h (terceira convocação)<br />
              Formato: Digital
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Acesse a assembleia e vote pelo internet banking ou pelo aplicativo Unicred Mobile, através do ícone "assembleias".
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Sua participação é importante para o futuro da nossa cooperativa!
            </p>
          </div>
        </div>
      </div>

      <div className="section-links" style={{ backgroundColor: "#3d3935" }}>
        <div className="section-links-container wrap">
          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-centro-sul-edital.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Edital
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-centro-sul-parecer-conselho-fiscal.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Parecer Conselho Fiscal
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2023/manual-unicred-web-2023.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Tutorial de acesso computador
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2023/manual-unicred-mobile-2023.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Tutorial de acesso celular
                </a>
              </li>
            </ul>
          </div>

          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-centro-sul-estatuto-social-ageo-02-04-2024.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Estatuto Social
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-centro-sul-demonstracoes-financeiras-12-2023.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Demonstrações Financeiras
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-centro-sul-demonstracoes-contabeis.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Demonstrações Contábeis
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-centro-sul-demonstrativo-sobras-ou-perdas.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Demonstrativo Sobras ou Perdas
                </a>
              </li>
            </ul>
          </div>

          <div className="section-links-info">
            <h1>Ficou com alguma dúvida ou quer enviar alguma sugestão?</h1>
            <p>
              Converse com seu gerente de relacionamento ou envie diretamente no canal de contato com o presidente da cooperativa:<br /><br />
              <a href="mailto:falecomapresidencia@unicred.com.br">falecomapresidencia@unicred.com.br</a>
            </p>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer-container wrap">
          <div className="footer-info">
            <a className="footer-info-site" href="https://unicred.com.br" target="_blank">unicred.com.br</a>

            <div className="footer-info-box">
              <h2>Agência Mais</h2>
              <p>Atendimento 24 horas por dia, 7 dias por semana.</p>
              <p>Esclarecimento de dúvidas, contribuição com sugestões, reclamações, cancelamento de serviços e elogios.</p>
              <p>Ligue: 4007-2440</p>
            </div>
          </div>

          <div className="footer-social">
            <div className="footer-social-box">
              <h2>Siga a Unicred nas redes sociais</h2>
              <ul className="social-list">
                <li><a href="https://www.instagram.com/unicredbrasil/" title="Instagram" target="_blank"><img src={iconInstagram} alt="Instagram" /></a></li>
                <li><a href="https://www.facebook.com/unicredbrasil" title="Facebook" target="_blank"><img src={iconFacebook} alt="Facebook" /></a></li>
                <li><a href="https://twitter.com/unicredbrasil" title="Twitter" target="_blank"><img src={iconTwitter} alt="Twitter" /></a></li>
                <li><a href="https://www.youtube.com/channel/UCdowVMAIRENRC2VH0_gcdww" title="Youtube" target="_blank"><img src={iconYoutube} alt="Youtube" /></a></li>
                <li><a href="https://br.linkedin.com/company/unicredbr" title="Linkedin" target="_blank"><img src={iconLinkedin} alt="Linkedin" /></a></li>
              </ul>
            </div>

            <div className="footer-logo">
              <img src={unicredSelo} alt="Prêmio SomosCoop" />
              <img src={unicredLogo} alt="Unicred" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnicredCentroSul;
