import React, { useEffect, useState } from "react";

function OnlineUsers({ socket }) {
  const [onlineUsers, setOnlineUsers] = useState(0);

  useEffect(() => {
    if (socket) {
      socket.on("total_active_users_count", (total_users) =>
        setOnlineUsers(total_users)
      );
    }
  }, [socket]);


  return (
    <p className="counter-online-users">{`Usuários online: ${onlineUsers}`}</p>
  );
}

export default React.memo(OnlineUsers);
