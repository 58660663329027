import React, { lazy, Suspense } from "react";

import "./MyAccount.scss";

import { getToken } from "~/services/token";

const Form = lazy(() => import("./Form"));
const Navbar = lazy(() => import("~/components/Navbar"));
const LoginModal = lazy(() => import("~/components/Login"));

export default function MyAccount() {
  const token = getToken();

  return (
    <div className="container-fluid-home">
      <Suspense fallback={<div />}>
        <Navbar />
      </Suspense>
      <div>
        {!token ? (
          <Suspense fallback={<div />}>
            <LoginModal />
          </Suspense>
        ) : (
          <Suspense fallback={<div className="loading-container-videos" />}>
            <Form />
          </Suspense>
        )}
      </div>
    </div>
  );
}
