import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import preAssembleia from "../../../assets/landingpage/unicred/pre-assembleia.png";
import iconDownload from "../../../assets/landingpage/unicred/download.png";
import iconInstagram from "../../../assets/landingpage/unicred/instagram.png";
import iconFacebook from "../../../assets/landingpage/unicred/facebook.png";
import iconYoutube from "../../../assets/landingpage/unicred/youtube.png";
import iconLinkedin from "../../../assets/landingpage/unicred/linkedin.png";
import iconWhatsapp from "../../../assets/landingpage/unicred/whatsapp.png";
import unicredLogo from "../../../assets/landingpage/unicred/unicred.png";
import edital from "../../../assets/landingpage/unicred/unicred-porto-alegre-edital.png"

import "./styles.scss";

function UnicredPortoAlegre({ branch, domain }) {
  useEffect(() => {
    ReactGA.send({
      hitType: "event",
      eventCategory: "landingPage",
      eventAction: "access",
      eventLabel: domain,
    });
  }, []);

  return (
    <div className="lp-unicred">
      <div className="section-1">
        <div className="section-1-container wrap">
          <div className="section-1-image">
            <div className="section-1-position">
              <img src={preAssembleia} />
            </div>
          </div>
          <div className="section-1-info section-1-info-porto">
            <div className="section-1-info-title">
              <h1>Boas-vindas</h1>
              <h2>Assembleia Geral Ordinária (AGO) Digital e Eleições 2023</h2>
              <h3>Unicred Porto Alegre</h3>
            </div>

            <h1 className="title">Escolha <span>participar.</span></h1>
            <p>Fique por dentro dos detalhes sobre esse momento tão importante.</p>
            <ul>
              <li><strong>Data:</strong> 27/04/23, quinta-feira</li>
              <li><strong>Eleições:</strong> 9h às 20h</li>
              <li><strong>Plenária:</strong> 20h às 22h</li>
              <li><strong>Participação:</strong> pelo app Unicred Mobile ou Internet Banking</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo section-with-image">
        <div className="section-2-container wrap">
          <div className="section-2-info">
            <p>Veja o edital completo, entenda quem pode votar e ser votado, as atribuições de cada Conselho, conheça os candidatos ao Conselho de Administração e Conselho Fiscal</p>
          </div>

          <div className="section-2-video">
            <div className="section-image">
              <a href="https://issuu.com/escolhaunicredportoalegre/docs/ebook_candidatos?fr=sN2FjNDU5NDAzNjA" title="Visualizar edital completo" target="_blank">
                <img src={edital} alt="Edital" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo" style={{ backgroundColor: "#d6d1ca" }}>
        <div className="section-2-container wrap">
          <div className="section-4-info section-2-info">
            <h1 className="title">Cooperar com <span>a sua vida e inspirar futuros</span></h1>
            <p style={{ color: "#3d3935" }}>Conheça algumas histórias que mostram o impacto positivo que promovemos, juntos, na vida e saúde de tantas pessoas.</p>
          </div>

          <div className="section-2-video">
            <div className="section-2-player">
              <iframe
                height="490"
                src="https://www.youtube-nocookie.com/embed/AsUYGY_wLtA"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-links" style={{ backgroundColor: "#3d3935" }}>
        <div className="section-links-container wrap">
          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2023/unicred-porto-alegre-edital.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Edital
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2023/unicred-porto-alegre-estatuto-social-assinado.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Estatuto Social
                </a>
              </li>
            </ul>
          </div>

          <div>
          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2023/manual-unicred-web.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Tutorial de acesso computador
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2023/manual-unicred-mobile.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Tutorial de acesso celular
                </a>
              </li>
            </ul>
          </div>
          </div>

          <div className="section-links-info">
            <h1>Ficou com alguma dúvida?</h1>
            <p>Converse com seu Gerente de Relacionamento.<br /> Fale pelo WhatsApp<br /> <img src={iconWhatsapp} /> <a href="https://wa.me/558006462829" target="_blank">0800 646 2829</a>.</p>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer-container wrap">
          <div className="footer-info">
            <a className="footer-info-site" href="http://euescolhounicred.com.br" target="_blank">euescolhounicred.com.br</a>

            <div className="footer-info-box">
              <h2 style={{ marginBottom: 0 }}>Central de Relacionamento Unicred</h2>
              <h2>24 horas por dia, 7 dias por semana</h2>
              <p>3003 7703 (Capitais e Regiões Metropolitanas)</p>
              <p>0800 200 7302 (Demais Localidades)</p>
              <p>+55 800 200 7302 (WhatsApp)</p>
              <p>+55 11 3003 7703 (Ligações do Exterior)</p>
              <p>Chat no app Unicred Mobile e Internet Banking</p>
            </div>

            <div className="footer-info-box">
              <h2>SAC</h2>
              <p>0800 647 2930</p>
            </div>

            <div className="footer-info-box">
              <h2>Ouvidoria</h2>
              <p>0800 940 0602</p>
            </div>
          </div>

          <div className="footer-social">
            <div className="footer-social-box">
              <h2>Siga a Unicred nas redes sociais</h2>
              <ul className="social-list">
                <li><a href="https://www.instagram.com/unicredportoalegre" title="Instagram" target="_blank"><img src={iconInstagram} alt="Instagram" /></a></li>
                <li><a href="https://www.facebook.com/unicredportoalegre" title="Facebook" target="_blank"><img src={iconFacebook} alt="Facebook" /></a></li>
                <li><a href="https://br.linkedin.com/company/unicredportoalegre" title="Linkedin" target="_blank"><img src={iconLinkedin} alt="Linkedin" /></a></li>
                <li><a href="https://www.youtube.com/channel/UCIoAZqe1P1DSWWnK-nNjLOA" title="Youtube" target="_blank"><img src={iconYoutube} alt="Youtube" /></a></li>
              </ul>
            </div>

            <div className="footer-logo">
              <img src={unicredLogo} alt="Unicred" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnicredPortoAlegre;
