import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import preAssembleia from "../../../assets/landingpage/unicred/pre-assembleia.png";
import iconDownload from "../../../assets/landingpage/unicred/download.png";
import iconInstagram from "../../../assets/landingpage/unicred/instagram.png";
import iconFacebook from "../../../assets/landingpage/unicred/facebook.png";
import iconTwitter from "../../../assets/landingpage/unicred/twitter.png";
import iconYoutube from "../../../assets/landingpage/unicred/youtube.png";
import iconLinkedin from "../../../assets/landingpage/unicred/linkedin.png";
import unicredLogo from "../../../assets/landingpage/unicred/unicred.png";

import grafico1 from "../../../assets/landingpage/unicred/graficos/grafico-unicred-1.jpg";
import grafico2 from "../../../assets/landingpage/unicred/graficos/grafico-unicred-2.jpg";
import grafico3 from "../../../assets/landingpage/unicred/graficos/grafico-unicred-3.jpg";
import grafico4 from "../../../assets/landingpage/unicred/graficos/grafico-unicred-4.jpg";
import grafico5 from "../../../assets/landingpage/unicred/graficos/grafico-unicred-5.jpg";
import grafico6 from "../../../assets/landingpage/unicred/graficos/grafico-unicred-6.jpg";
import grafico7 from "../../../assets/landingpage/unicred/graficos/grafico-unicred-7.jpg";
import grafico8 from "../../../assets/landingpage/unicred/graficos/grafico-unicred-8.jpg";
import grafico9 from "../../../assets/landingpage/unicred/graficos/grafico-unicred-9.jpg";
import grafico10 from "../../../assets/landingpage/unicred/graficos/grafico-unicred-10.jpg";
import grafico11 from "../../../assets/landingpage/unicred/graficos/grafico-unicred-11.jpg";

import "./styles.scss";

function UnicredUniao({ domain }) {
  useEffect(() => {
    ReactGA.send({
      hitType: "event",
      eventCategory: "landingPage",
      eventAction: "access",
      eventLabel: domain,
    });
  }, []);

  return (
    <div className="lp-unicred">
      <div className="section-main">
        <div className="section-main-container wrap">
          <div className="section-main-info">
            <h1>
              A Unicred União convida você para
              <span>a Pré-Assembleia</span>
              <span>Digital Ordinária!</span>
            </h1>

            <p>
              Assista ao vídeo do Presidente da Unicred União para conhecer as principais realizações e os números da cooperativa em 2023.
            </p>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo">
        <div className="section-2-container wrap">
          <div className="section-2-info section-2-info-unicred-vale">
            <h1 className="title-new">Mauro <span>Marquiotti</span></h1>
            <p>Presidente da Unicred União</p>
          </div>

          <div className="section-2-video">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-uniao-dr-mauro-marquiotti.m4v" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="section-form" style={{ backgroundColor: "#332f2a" }}>
        <div className="wrap">
          <div className="section-form-info">
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Cada vez melhor e mais forte
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              A Unicred União cresceu e apresentou em 2023 excelentes resultados nos principais indicadores de saúde financeira.
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Eles traduzem solidez, credibilidade e, acima de tudo, renovam o compromisso da cooperativa com uma gestão responsável e eficiente. 
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Para nós, excelência é isso: fazer bem-feito para merecer a confiança dos cooperados.
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Os números a seguir resumem a nossa performance em 2023.
            </p>
          </div>
        </div>
      </div>

      <div className="section-graphic">
        <div className="section-graphic-container">
          <img src={grafico1} alt="" />
          <img src={grafico2} alt="" />
          <img src={grafico3} alt="" />
          <img src={grafico4} alt="" />
          <img src={grafico5} alt="" />
          <img src={grafico6} alt="" />
          <img src={grafico7} alt="" />
          <img src={grafico8} alt="" />
          <img src={grafico9} alt="" />
          <img src={grafico10} alt="" />
          <img src={grafico11} alt="" />
        </div>
      </div>

      <div className="section-form" style={{ backgroundColor: "#332f2a" }}>
        <div className="wrap">
          <div className="section-form-info">
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Participe da Assembleia Geral Ordinária da Unicred União!
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Data: 14 de março de 2024 <br />
              Horário: 19h (terceira convocação)
              Formato: Digital
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Acesse a assembleia e vote pelo internet banking ou pelo aplicativo Unicred Mobile, através do ícone "assembleias".
            </p>
            <p style={{ paddingLeft: 0, maxWidth: 'none' }}>
              Sua participação é importante para o futuro da nossa cooperativa.
            </p>
          </div>
        </div>
      </div>

      <div className="section-links" style={{ backgroundColor: "#3d3935" }}>
        <div className="section-links-container wrap">
          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-uniao-edital-2024.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Edital
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-uniao-relatorio-anual-2023.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Relatório Anual
                </a>
              </li>
            </ul>
          </div>

          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-uniao-demonstracao-financeiras-e-parecer-auditoria.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Demonstrações e Parecer
                </a>
              </li>
            </ul>
          </div>

          <div className="section-links-info">
            <h1>Ficou com alguma dúvida ou quer enviar alguma sugestão?</h1>
            <p>
              Converse com seu gerente de relacionamento ou envie diretamente no canal de contato com o presidente da cooperativa:<br /><br />
              <a href="mailto:falecomapresidencia@unicred.com.br">falecomapresidencia@unicred.com.br</a>
            </p>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer-container wrap">
          <div className="footer-info">
            <a className="footer-info-site" href="https://unicred.com.br" target="_blank">unicred.com.br</a>

            <div className="footer-info-box">
              <h2>Agência Mais</h2>
              <p>Atendimento 24 horas por dia, 7 dias por semana.</p>
              <p>Esclarecimento de dúvidas, contribuição com sugestões, reclamações, cancelamento de serviços e elogios.</p>
              <p>Ligue: 4007-2440</p>
            </div>
          </div>

          <div className="footer-social">
            <div className="footer-social-box">
              <h2>Siga a Unicred nas redes sociais</h2>
              <ul className="social-list">
                <li><a href="https://www.instagram.com/unicredbrasil/" title="Instagram" target="_blank"><img src={iconInstagram} alt="Instagram" /></a></li>
                <li><a href="https://www.facebook.com/unicredbrasil" title="Facebook" target="_blank"><img src={iconFacebook} alt="Facebook" /></a></li>
                <li><a href="https://twitter.com/unicredbrasil" title="Twitter" target="_blank"><img src={iconTwitter} alt="Twitter" /></a></li>
                <li><a href="https://www.youtube.com/channel/UCdowVMAIRENRC2VH0_gcdww" title="Youtube" target="_blank"><img src={iconYoutube} alt="Youtube" /></a></li>
                <li><a href="https://br.linkedin.com/company/unicredbr" title="Linkedin" target="_blank"><img src={iconLinkedin} alt="Linkedin" /></a></li>
              </ul>
            </div>

            <div className="footer-logo">
              <img src={unicredLogo} alt="Unicred" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnicredUniao;
