import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import iconDownload from "../../../assets/landingpage/unicred/download.png";
import iconInstagram from "../../../assets/landingpage/unicred/instagram.png";
import iconFacebook from "../../../assets/landingpage/unicred/facebook.png";
import iconTwitter from "../../../assets/landingpage/unicred/twitter.png";
import iconYoutube from "../../../assets/landingpage/unicred/youtube.png";
import iconLinkedin from "../../../assets/landingpage/unicred/linkedin.png";
import iconWhatsapp from "../../../assets/landingpage/unicred/whatsapp.png";
import unicredLogo from "../../../assets/landingpage/unicred/unicred.png";
import convite from "../../../assets/landingpage/unicred/prosperar/convite.jpg";
import numberosBrasil from "../../../assets/landingpage/unicred/prosperar/numeros-brasil-v2.jpg";
import numberosUnicredProsperar from "../../../assets/landingpage/unicred/prosperar/numeros-unicred-prosperar.png";

import "./styles.scss";

function UnicredProsperar({ branch, domain }) {
  useEffect(() => {
    ReactGA.send({
      hitType: "event",
      eventCategory: "landingPage",
      eventAction: "access",
      eventLabel: domain,
    });
  }, []);

  return (
    <div className="lp-unicred">
      <div className="section-main">
        <div className="section-main-container wrap">
          <div className="section-main-info">
            <h1>
              <p>UNICRED PROSPERAR</p>
              <span>ASSEMBLEIA GERAL EXTRAORDINÁRIA E ORDINÁRIA HÍBRIDA</span>
            </h1>

            <p>
              Que bom que você chegou!<br />
              Sua presença e voto são fundamentais para definirmos o futuro da nossa cooperativa. Venha conosco, pois a ASSEMBLEIA GERAL EXTRAORDINÁRIA E ORDINÁRIA irá começar!
            </p>
          </div>
        </div>
      </div>

      <div className="section-2 without-selo">
        <div className="section-2-container wrap">
          <div className="section-2-info section-2-info-unicred-vale">
            <p>Na Unicred, acreditamos no poder da cooperação para impulsionar o sucesso financeiro de nossos cooperados e fortalecer as comunidades nas quais estamos inseridos. Junte-se a nós para fortalecer laços e realizar sonhos.<br />Confira nosso vídeo institucional.</p>
          </div>

          <div className="section-2-video">
            <div className="section-2-player">
              <video controls>
                <source src="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-prosperar-institucional.mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="prosperar-numeros-brasil">
        <img src={numberosBrasil} alt="" />
      </div>

      <div className="section-2 without-selo">
        <div className="wrap">
          <div className="section-info">
            <h1 className="title-new">MENSAGEM DO PRESIDENTE</h1>
            <p>
              Em nossa jornada rumo à prosperidade, reconhecemos a importância de alcançar resultados cada vez mais sólidos. É por isso que a Unicred se destaca como o parceiro ideal para acompanhá-lo nessa trajetória. Aqui, enxergamos cada cooperado não apenas como um cliente, mas um verdadeiro sócio da cooperativa, alguém que tem voz ativa e participação direta nas decisões que moldam nosso negócio.
            </p>
            <p>
              Como uma instituição financeira, compreendemos que os números desempenham um papel crucial em nossas operações. No entanto, valorizamos ainda mais o aspecto humano de nossas relações. Afinal, são as pessoas que impulsionam nosso sucesso e nos motivam a alcançar novos patamares de excelência. Buscamos entender suas necessidades e oferecer soluções financeiras que realmente façam a diferença em suas vidas.	
            </p>
            <p>
              Nos últimos anos, testemunhamos um notável progresso e expansão além das fronteiras do Rio Grande do Sul. Hoje, celebramos não apenas um ano de conquistas em Goiás, mas também o destaque de nossa produção histórica em crédito e consórcios, resultado do compromisso de nossa equipe e do apoio contínuo de nossos cooperados.	
            </p>
            <p>
              Além disso, reafirmamos nosso compromisso com o crescimento sustentável financeiro com nossos cooperados e a responsabilidade social na comunidade na qual estamos inseridos. Acreditamos que construir uma comunidade forte e resiliente é fundamental para promover o bem-estar de todos. Por isso, continuaremos a inovar e a utilizar tecnologias de ponta para tornar nossos processos cada vez mais eficientes, garantindo que possamos atender às necessidades em constante evolução de nossos cooperados.	
            </p>
            <p>
              A medida que avançamos para o futuro, permanecemos unidos em nosso espírito cooperativista, pois sabemos que é somente através da união que poderemos alcançar nossos objetivos e garantir o sucesso contínuo da Unicred. Juntos, continuaremos construindo uma organização sólida e comprometida com o bem-estar de todos, inspirando confiança e prosperidade em todas as comunidades que estamos inseridos.
            </p>
          </div>
        </div>
      </div>

      <div className="prosperar-numeros-brasil" style={{ backgroundColor: "#4e4d4d" }}>
        <img src={numberosUnicredProsperar} alt="" />
      </div>
      
      <div className="prosperar-banner">
        <img src={convite} alt="" />
      </div>

      <div className="section-links" style={{ backgroundColor: "#3d3935" }}>
        <div className="section-links-container wrap">
          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-prosperar-edital.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Edital
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-prosperar-reforma-estatutaria.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Reforma Estatutária
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-prosperar-relatorio-de-gestao.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Relatório de Gestão
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-prosperar-balanco-patrimonial-e-demonstracoes-financeiras.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Balanço Patrimonial e Demonstrações Financeiras
                </a>
              </li>
            </ul>
          </div>

          <div>
            <ul className="links2">
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-prosperar-parecer-do-conselho-fiscal.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Parecer do conselho fiscal
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-prosperar-carta-da-comissao-eleitoral.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Carta da Comissão Eleitoral
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2024/unicred-prosperar-politica-de-remuneracao-da-diretoria-executiva.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Política de Remuneração da Diretória Executiva
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2023/manual-unicred-web-2023.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Tutorial de acesso computador
                </a>
              </li>
              <li>
                <a href="https://hallo-static.s3.sa-east-1.amazonaws.com/customers/unicred/2023/manual-unicred-mobile-2023.pdf" target="_blank">
                  <span><img src={iconDownload} /></span>
                  Tutorial de acesso celular
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer-container wrap">
          <div className="footer-info">
            <a className="footer-info-site" href="https://unicred.com.br" target="_blank">unicred.com.br</a>

            <div className="footer-info-box">
              <h2 style={{ marginBottom: 0 }}>Central de Relacionamento Unicred</h2>
              <h2>24 horas por dia, 7 dias por semana</h2>
              <p>3003 7703 (Capitais e Regiões Metropolitanas)</p>
              <p>0800 200 7302 (Demais Localidades)</p>
              <p>+55 800 200 7302 (WhatsApp)</p>
              <p>+55 11 3003 7703 (Ligações do Exterior)</p>
              <p>Chat no app Unicred Mobile e Internet Banking</p>
            </div>

            <div className="footer-info-box">
              <h2>SAC</h2>
              <p>0800 647 2930</p>
            </div>

            <div className="footer-info-box">
              <h2>Ouvidoria</h2>
              <p>0800 940 0602</p>
            </div>
          </div>

          <div className="footer-social">
            <div className="footer-social-box">
              <h2>Siga a Unicred nas redes sociais</h2>
              <ul className="social-list">
                <li><a href="https://www.instagram.com/unicredbrasil/" title="Instagram" target="_blank"><img src={iconInstagram} alt="Instagram" /></a></li>
                <li><a href="https://www.facebook.com/unicredbrasil" title="Facebook" target="_blank"><img src={iconFacebook} alt="Facebook" /></a></li>
                <li><a href="https://twitter.com/unicredbrasil" title="Twitter" target="_blank"><img src={iconTwitter} alt="Twitter" /></a></li>
                <li><a href="https://www.youtube.com/channel/UCdowVMAIRENRC2VH0_gcdww" title="Youtube" target="_blank"><img src={iconYoutube} alt="Youtube" /></a></li>
                <li><a href="https://br.linkedin.com/company/unicredbr" title="Linkedin" target="_blank"><img src={iconLinkedin} alt="Linkedin" /></a></li>
              </ul>
            </div>

            <div className="footer-logo">
              <img src={unicredLogo} alt="Unicred" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnicredProsperar;
