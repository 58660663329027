import React from "react";

import "./success.styles.scss";

import SuccessIcon from "~/assets/icons/success.svg";

const Success = () => {
  return (
    <div className="container-success">
      <div className="success-content">
        <img src={SuccessIcon} alt="success icon" />
        <h3>Candidatura registrada com sucesso!</h3>
        <p>Entraremos em contato para validar as informações.</p>
      </div>
    </div>
  );
};

export default Success;
