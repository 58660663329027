import React from "react";
import { LandingPageProvider } from "./LandingPageContext";
import Uniprime from "./Uniprime";
import UnicredVale from "./Unicred/UnicredVale";
import UnicredProsperar from "./Unicred/UnicredProsperar";
import UnicredValorCapital from "./Unicred/UnicredValorCapital";
import UnicredUniao from "./Unicred/UnicredUniao";
import UnicredCentroSul from "./Unicred/UnicredCentroSul";
import UnicredPortoAlegre from "./Unicred/UnicredPortoAlegre";

import parse from "html-react-parser";

function Landingpage({ info }) {
  return (
    <LandingPageProvider>
      {info.branch === 649 ? (
        <Uniprime
          type="Ordinária"
          branch={info.branch}
        />
      ) : info.branch === 590 ? (
        <UnicredVale branch={info.branch} />
      ) : info.branch === 124 ? (
        <UnicredProsperar branch={info.branch} />
      ) : info.branch === 515 ? (
        <UnicredValorCapital branch={info.branch} />
      ) : info.branch === 582 ? (
        <UnicredUniao branch={info.branch} />
      ) : info.branch === 566 ? (
        <UnicredCentroSul branch={info.branch} />
      ) : info.branch === 27 ? (
        <UnicredPortoAlegre branch={info.branch} />
      ) : (
        info.content && <div>{parse(info.content)}</div>
      )}
    </LandingPageProvider>
  );
}

export default Landingpage;
