import React, { useMemo, useRef, useState } from "react";

import axios from "axios";
import { toast } from "react-toastify";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";

import { InputMask } from "~/components/Input/InputComponent/InputMask";
import { Input } from "~/components/Input/InputComponent";
import { Select } from "~/components/Input/InputComponent/Select";
import { AsyncSelect } from "~/components/Input/InputComponent/Select/AsyncSelect";
import { Checkbox } from "~/components/Input/InputComponent/Checkbox";
import Button from "~/components/Button/Button";
import Loading from "~/components/Loading";

import api from "~/services/api";
import api_url from "~/services/api_url";

import {
  parsePreviewUrl,
  getFileType,
  removeCharactersExceptNumbers,
} from "~/utils";

import "./form.styles.scss";
import Success from "../Success";

const schemaDelegateValidation = yup
  .object({
    cpf: yup.string().required("O CPF é obrigatório."),
    cooperative: yup.string().required("A cooperativa é obrigatória."),
    phone: yup.string().required("O telefone é obrigatório."),
    email: yup.string().required("Email é obrigatório."),
    occupation: yup.object({}).required("A profissão é obrigatória."),
    photo: yup.mixed().nullable().required("A foto é obrigatória."),
    direct: yup.bool().oneOf([true], "Campo obrigatório"),
  })
  .required();

const DelegateForm = ({ cooperatives: cooperativesToParse }) => {
  const [photo, setPhoto] = useState(null);
  const [requestInitialOptions, setRequestInitialOptions] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, seIsLoading] = useState(false);

  const inputFileRef = useRef(null);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaDelegateValidation),
  });

  const cooperatives = useMemo(() => {
    const parsedCooperatives = cooperativesToParse.map(({ code, name }) => ({
      label: name,
      value: code,
    }));

    return parsedCooperatives;
  }, [cooperativesToParse]);

  function handleUpload(event) {
    const types = ["jpg", "jpeg", "png"];
    const value = event.target.files[0];
    const fileType = getFileType(value.name);

    if (!fileType || !types.includes(fileType.toLowerCase()))
      return toast.error("Insira apenas JPG, JPEG ou PNG");

    setValue("photo", value);

    setPhoto(value);
  }

  async function fetchOccupation(inputValue) {
    let params = [];
    let url = `${api_url}/v1/occupations`;

    if (requestInitialOptions) params.push("limit=100");

    if (inputValue) params.push(`name=${inputValue}`);

    if (params.length > 0) url += `?${params.join("&")}`;

    let response = await fetch(url);

    response = await response.json();

    setRequestInitialOptions(false);

    const parsedOccupations = response.map(({ name }) => ({
      label: name,
      value: name,
    }));

    return parsedOccupations;
  }

  async function onSubmit({ cpf, phone, cooperative, email, occupation }) {
    try {
      seIsLoading(true);

      const delegateData = {
        photo,
        document_number: removeCharactersExceptNumbers(cpf),
        phone: removeCharactersExceptNumbers(phone),
        email,
        branch: cooperative,
        occupation: occupation.value,
      };

      if (photo instanceof File) {
        const fileType = getFileType(photo.name);

        const { data } = await api.get(
          `/v1/file-upload?context=delegates&extension=${fileType}`
        );
        await axios.create().put(data.url, photo);

        const url = data.url.split("?")[0];

        delegateData.photo = url;
      } else {
        delegateData.photo = photo;
      }

      await api.post("/v1/delegates", delegateData);

      setIsSubmitted(true);
    } catch (error) {
      const NOT_FOUND_RESOURCE_STATUS_CODE = 404;

      if (error.response.status === NOT_FOUND_RESOURCE_STATUS_CODE)
        toast.error("Cooperado não encontrado");

      console.error("Error to submit delegates ", error);
    } finally {
      seIsLoading(false);
    }
  }

  return (
    <>
      {!isSubmitted ? (
        <div className="wrapper">
          <div className="form-wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="upload-input">
                <div className="upload-input-container">
                  {photo && (
                    <div
                      className="upload-input-remove"
                      onClick={() => {
                        inputFileRef.current.value = "";
                        setPhoto("");
                      }}
                      title="Remover"
                    >
                      <div className="icon-close" />
                    </div>
                  )}

                  <label htmlFor="photo" title="Foto">
                    {photo && <img src={parsePreviewUrl(photo)} alt="" />}

                    <div className="upload-input-overflow">
                      <div className="icon-photo-camera" />
                    </div>
                  </label>

                  <input
                    ref={inputFileRef}
                    id="photo"
                    type="file"
                    accept="image/*"
                    {...register("photo")}
                    onChange={handleUpload}
                  />
                  {errors.photo && (
                    <span className="error-message">
                      {errors.photo.message}
                    </span>
                  )}
                </div>
              </div>
              <InputMask
                control={control}
                label="CPF"
                name="cpf"
                mask="999.999.999-99"
                error={errors.cpf}
              />
              <Select
                control={control}
                options={cooperatives}
                label="Cooperativa"
                name="cooperative"
                placeholder="Selecione uma cooperativa"
                error={errors.cooperative}
              />
              <InputMask
                control={control}
                label="Telefone"
                name="phone"
                mask="(99) 99999-9999"
                error={errors.phone}
              />

              <Input
                label="Email"
                {...register("email")}
                error={errors.email}
              />
              <AsyncSelect
                label="Profissões"
                name="occupation"
                control={control}
                loadOptions={fetchOccupation}
                noOptionsMessage={() => "Não encontramos essa profissão."}
                loadingMessage={() => "Buscando..."}
                placeholder="Digite sua profissão"
                error={errors.occupation}
              />
              <Checkbox
                label="Estou ciente das atribuições e responsabilidades dos Delegados"
                {...register("direct")}
                error={errors.direct}
              />
              <Button>{!isLoading ? "Salvar" : <Loading size={2} />}</Button>
            </form>
          </div>
        </div>
      ) : (
        <Success />
      )}
    </>
  );
};

export default DelegateForm;
