export function chatModal(open) {
  return {
    type: "@chat/CHAT_MODAL",
    payload: {
      open,
    },
  };
}

export function messageToSend(message_to_send) {
  return {
    type: "@chat/MESSAGE_TO_SEND",
    payload: {
      message_to_send,
    }
  }
}

export function livestreamGuid(livestream_guid) {
  return {
    type: "@chat/LIVESTREAM_GUID",
    payload: {
      livestream_guid,
    },
  };
}

export function renderIcon(render_icon) {
  return {
    type: "@chat/RENDER_ICON",
    payload: {
      render_icon,
    },
  };
}

export function allowPrivateChat(allow_private_chat) {
  return {
    type: "@chat/ALLOW_PRIVATE_CHAT",
    payload: {
      allow_private_chat,
    },
  };
}
