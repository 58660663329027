const options = {
  settings: {
    disableKeyboardControls: true,
    disableWheelControls: true
  },
  buttons: {
    showAutoplayButton: false,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: false,
    showPrevButton: false,
    showThumbnailsButton: false
  },
  thumbnails: {
    showThumbnails: false
  }
}

export default options;