import React, { useEffect, useState } from "react";

import "./style.scss";

import arrowDown from "~/assets/imgs/hallo/arrow-down.svg";
import ExclamationQuestion from "~/assets/imgs/hallo/exclamation-question.svg";
import Checkbox from "~/components/Checkbox";

const Question = ({
  question,
  handleChangeAnswer,
  currentQuestion,
  currentQuestionRef,
  answers,
  questionIndex,
  setCurrentQuestion,
}) => {
  const [aleradyOpened, setAleradyOpened] = useState(false);
  const [collapse, setCollapse] = useState(currentQuestion === questionIndex);

  useEffect(() => {
    setCollapse(currentQuestion === questionIndex);
    if (!aleradyOpened && currentQuestion === questionIndex) {
      setAleradyOpened(true);
    }
  }, [currentQuestion]);
  const toggleCollapse = (e) => {
    setCurrentQuestion(questionIndex);
    if (!aleradyOpened) {
      setAleradyOpened(true);
    }
    setCollapse((prev) => !prev);
  };
  const selectedOptionRender = () => {
    const answer = answers[question.guid]?.pollOptionGuid;
    const option = question.options.filter((option) => option.guid === answer);
    return option ? option[0]?.title : null;
  };
  return (
    <div
      className={`formal-consultation-question ${
        answers[question.guid]?.pollOptionGuid
          ? "formal-consultation-question--active-bg"
          : ""
      }`}
      ref={currentQuestion === questionIndex ? currentQuestionRef : null}
    >
      <div
        className="formal-consultation-question__header"
        onClick={() => toggleCollapse()}
      >
        <h2>{question.title}</h2>
        {aleradyOpened &&
        !answers[question.guid]?.pollOptionGuid &&
        !collapse ? (
          <img
            src={ExclamationQuestion}
            className="formal-consultation-question__header__exclamation"
            alt="Exclamação"
          />
        ) : (
          <img
            src={arrowDown}
            className={`${
              aleradyOpened && collapse
                ? "formal-consultation-question__header__arrow--up"
                : "formal-consultation-question__header__arrow--down"
            }`}
            alt="Seta"
          />
        )}
      </div>
      {answers[question.guid]?.pollOptionGuid && !collapse && (
        <span className="formal-consultation-question__selected-option">
          {selectedOptionRender()}
        </span>
      )}
      {aleradyOpened && collapse && currentQuestion === questionIndex && (
        <div className={`formal-consultation-question__body`}>
          {question.options.map((option, index) => (
            <Checkbox
              type="radio"
              key={option.guid}
              name={`question-${question.guid}`}
              label={option.title}
              value={option.guid}
              onChange={(e) => {
                toggleCollapse();
                handleChangeAnswer(question.guid, option.guid, questionIndex);
              }}
              checked={answers[question.guid]?.pollOptionGuid === option.guid}
            />
          )).reverse()}
        </div>
      )}
    </div>
  );
};
export default Question;
