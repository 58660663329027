import React, { useEffect, useState } from "react";
import Countdown from "../Countdown/Countdown";
import ButtonSecondary from "~/components/Button/ButtonSecondary";
import ZoomRequest from "./ZoomRequest";

function LivestreamHeader({
  livestream,
  durationTime,
  handleDocuments,
  isZoomApproved,
  isZoomRejected,
  hasZoomRequested,
  handleApproveZoomRequest,
  livestreamGuid,
  showZoom,
  socket,
  totalDocuments,
}) {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    const REGEX_DEVICE =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i;
    if (navigator.userAgent.match(REGEX_DEVICE) !== null)
      setIsMobileDevice(true);
  }, []);

  return (
    <div className="container-title-documents">
      <div className="container-title">
        <h1>{livestream.name}</h1>
        <Countdown durationTime={durationTime} />
      </div>
      {showZoom &&
        !livestream.pre_poll_enabled &&
        !livestream.pos_poll_enabled && (
          <ZoomRequest
            isZoomApproved={isZoomApproved}
            isZoomRejected={isZoomRejected}
            hasZoomRequested={hasZoomRequested}
            handleApproveZoomRequest={handleApproveZoomRequest}
            livestreamGuid={livestreamGuid}
            isStreamming={livestream.playback_id}
            socket={socket}
          />
        )}
      <div className="container-button" onClick={handleDocuments}>
        <span
          className={`mobile-label icon-download-icon ${
            totalDocuments === 0 ? "hidden-budge" : ""
          }`}
          data-content={totalDocuments}
        />
        <ButtonSecondary
          className={`full-label ${totalDocuments === 0 ? "hidden-budge" : ""}`}
          data-content={totalDocuments}
        >
          Baixar Arquivos
        </ButtonSecondary>
      </div>
    </div>
  );
}

export default LivestreamHeader;
