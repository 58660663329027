import produce from "immer";

const INITIAL_STATE = {
    visible: false,
    content: null,
    backdropCallback: null
};

export default function chat(state = INITIAL_STATE, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case "@modal/OPEN_MODAL": {
                draft.content = action.payload.content;
                draft.visible = true;
                draft.backdropCallback = action.payload.backdropCallback;
                break;
            }
            case "@modal/CLOSE_MODAL": {
                draft.visible = false;
                action.payload.closeCallback();
                break;
            }
            case "@modal/RESET_MODAL_STATE": {
                draft.visible = INITIAL_STATE.visible;
                draft.content = INITIAL_STATE.content;
                draft.backdropCallback = INITIAL_STATE.backdropCallback;
                break;
            }
            default:
        }
    });
}
