import logo from '../assets/imgs/unimed/logo.png'
import logo_white from '../assets/imgs/unimed/logo_white.svg'

export default {
  logo,
  logo_white,
  name: "Unimed",
  title: "Unimed Assembleias",
  appcode: 2,
  captchaSiteKey: "6LebO8IhAAAAAApCTVQlIKAmnnP2suTrTsWAuAUk",
  facebookAppId: "xxx261213698471647",
  googleAppId: "xxxx820782713227-lp4uepgj2066jnp9e6ca76p59gr1t2a5.apps.googleusercontent.com",
  googleTrackingId: "G-J2CRKSSP2R",
  showPA:  false,
  showGroup: false,
  allowPublicMessages: false,
  allowPrivateMessages: true,
  accessCodeByEmail: true,
  enableGuestAccess: false,
  showMyLivestreamsOnly: true,
  prePollTitle: "Pré Assembleia",
  showMyLivestreamsByAgencyAndGroup: false,
  showBallotPaper: false
};
