import React from "react";

import "./Button.scss";

import Button from "./Button";

export default ({ className, children, ...props }) => {
  let classes = `btn button-secondary ${className}`;

  return (
    <Button {...props} className={classes}>
      {children}
    </Button>
  );
};
