import React from "react";

import ExclamationQuestion from "~/assets/imgs/hallo/exclamation-question.svg";

import ButtonSecondary from "~/components/Button/ButtonSecondary";

import "./style.scss";

export default (backButtonCallback) => {

    return (
        <>
            <div className="modal-formal-consultation">
                <img src={ExclamationQuestion} alt="Exclamation mark icon" />
                <h4 className="modal-formal-consultation__title">
                    Você já votou
                </h4>
                <p className="modal-formal-consultation__paragraph">
                    Só é permitido votar uma vez por assembléia
                </p>
                <div className="modal-formal-consultation__actions">
                    <ButtonSecondary onClick={backButtonCallback}
                        className="modal-formal-consultation__actions__btn-back">
                        Voltar
                    </ButtonSecondary>
                </div>
            </div>
        </>
    )
}