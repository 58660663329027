import React from "react";
import Audio from "~/components/Audio/Audio";
import closeIcon from "~/assets/imgs/hallo/close.svg";
import "./ReplyComment.scss"
function ReplyComment({repliedMessage, setRepliedMessage, repliedRef}) {
  return (
    <div
      className={`reply-message ${
        !!repliedMessage ? "show-animation" : "hidden"
      }`}
      ref={repliedRef}
    >
      {repliedMessage?.attachment_type === "mp3" ? (
        <Audio audio={repliedMessage?.attachment} />
      ) : (
        <p className="reply-message__content">{repliedMessage?.message}</p>
      )}
      <button className="reply-message__close" onClick={() => setRepliedMessage(null)}>
        <img className="reply-message__close-icon"src={closeIcon} alt="Close icon reply message" />
      </button>
    </div>
  );
}

export default ReplyComment;
