import produce from "immer";

const INITIAL_STATE = {
  user_rules: null
};

export default function user_rules(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@user_rules/user_rules": {
        draft.user_rules = action.payload.user_rules;
        break;
      }
      default:
    }
  });
}
