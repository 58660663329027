import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import "./redirect.styles.scss";

const TIME_IN_MILLISECONDS_TO_REDIRECT = 5000;

function Redirect() {
  const history = useHistory();

  useEffect(() => {
    const timeoutToRedirectUser = setTimeout(() => {
      history.push("/");
    }, TIME_IN_MILLISECONDS_TO_REDIRECT);
    return () => clearTimeout(timeoutToRedirectUser);
  }, []);

  return (
    <div className="wrapper">
      <div className="container">
        <h1>Não estamos no período de matrícula de novos delegados!</h1>
        <span>Você vai ser redirecionado para a página inicial.</span>
      </div>
    </div>
  );
}

export default Redirect;
