import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { IconClose } from "~/components/icons";
import "./styles.scss";

export default function BottomBar() {
  const location = useLocation();
  const bottomBarContent = useSelector((store) => store.configs.bottom_bar_content);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (bottomBarContent) {
      setOpen(true);
    }
  }, [location, bottomBarContent]);
  
  function handleClose() {
    setOpen(false);
  }

  if (!open) return null;

  return (
    <div className="bottom-bar-container">
      <button
        type="button"
        className="bottom-bar-close"
        title="Fechar"
        onClick={handleClose}
      >
        <IconClose color="#ffffff" />
      </button>

      <div
        className="bottom-bar-content"
        dangerouslySetInnerHTML={{ __html: bottomBarContent }}
      />
    </div>
  );
}
