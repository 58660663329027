import React, { useRef } from "react";

import { formatDuration } from "~/services/utils"
export default function Bar(props) {
    const { duration, curTime, onTimeUpdate } = props;

    const curPercentage = (curTime / duration) * 100;
    const progress = useRef()

    function calcClickedTime(e) {
        const clickPositionInPage = e.pageX;
        const barStart = progress.current.getBoundingClientRect().left + window.scrollX;
        const barWidth = progress.current.offsetWidth;
        const clickPositionInBar = clickPositionInPage - barStart;
        const timePerPixel = duration / barWidth;
        return timePerPixel * clickPositionInBar;
    }

    function handleTimeDrag(e) {
        onTimeUpdate(calcClickedTime(e));

        const updateTimeOnMove = eMove => {
            onTimeUpdate(calcClickedTime(eMove));
        };

        progress.current.addEventListener("mousemove", updateTimeOnMove);

        progress.current.addEventListener("mouseup", () => {
            progress.current.removeEventListener("mousemove", updateTimeOnMove);
        });
    }

    return (    
        <div className="bar">
            <span className="time">{formatDuration(curTime)}</span>
            <div
                className="progress"
                style={{
                    background: `linear-gradient(to right, var(--secondary_color) ${curPercentage}%, white 0)`
                }}
                onMouseDown={e => handleTimeDrag(e)}
                ref={progress}
            >
                <div className="progress__background"
                    style={{
                        width: `${curPercentage}%`
                    }} />
                <span className="progress__knob" style={{ left: `${curPercentage - 2}%` }} />
            </div>
            <span className="time">{duration && duration !== Infinity && formatDuration(duration)}</span>
        </div>
    );
}
