import produce from "immer";

const INITIAL_STATE = {
  open: false,
  message_to_send: null,
  livestream_guid: "",
  render_icon: false,
  allow_private_chat: false
};

export default function chat(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@chat/CHAT_MODAL": {
        draft.open = action.payload.open;
        break;
      }
      case "@chat/MESSAGE_TO_SEND": {
        draft.message_to_send = action.payload.message_to_send;
        break;
      }
      case "@chat/LIVESTREAM_GUID": {
        draft.livestream_guid = action.payload.livestream_guid;
        break;
      }
      case "@chat/RENDER_ICON": {
        draft.render_icon = action.payload.render_icon;
        break;
      }
      case "@chat/ALLOW_PRIVATE_CHAT": {
        draft.allow_private_chat = action.payload.allow_private_chat;
        break;
      }
      default:
    }
  });
}
