import customer from '~/customer';

const localStorageKey = `__hallo_live_authenticated_url__${customer}`;

export function getAuthenticatedUrl() {
  return window.localStorage.getItem(localStorageKey);
}

export function setAuthenticatedUrl() {
  window.localStorage.setItem(localStorageKey, true);
}

export function removeAuthenticatedUrl() {
  window.localStorage.removeItem(localStorageKey);
}
