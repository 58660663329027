import logo from '../assets/imgs/hallo/logo.svg'
import logo_white from '../assets/imgs/hallo/logo.svg'

export default {
    logo,
    logo_white,
    name: "Hallo",
    title: "Hallo Assembleias",
    appcode: 2,
    captchaSiteKey: "6LebO8IhAAAAAApCTVQlIKAmnnP2suTrTsWAuAUk",
    facebookAppId: "",
    googleAppId: "",
    googleTrackingId: "G-J2CRKSSP2R",
    showPA: true,
    showGroup: true,
    allowPublicMessages: true,
    allowPrivateMessages: true,
    accessCodeByEmail: false,
    enableGuestAccess: true,
    showMyLivestreamsByAgencyAndGroup: false,
    showBallotPaper: false,
    prePollTitle: "Pré Assembleia",
    prePollText: "Pré Assembleia",
    posPollText: "Pós Assembleia"
};
