import logo from '../assets/imgs/ailos/Logo.svg'
import logo_white from '../assets/imgs/ailos/Logo.svg'
import logo_acentra_white from '../assets/imgs/ailos/whites/logo_acentra.svg'
import logo_acredicoop_white from '../assets/imgs/ailos/whites/logo_acredicoop.svg'
import logo_civia_white from '../assets/imgs/ailos/whites/logo_civia.svg'
import logo_credcrea_white from '../assets/imgs/ailos/whites/logo_credcrea.svg'
import logo_credelesc_white from '../assets/imgs/ailos/whites/logo_credelesc.svg'
import logo_credicomin_white from '../assets/imgs/ailos/whites/logo_credicomin.svg'
import logo_credifoz_white from '../assets/imgs/ailos/whites/logo_credifoz.svg'
import logo_crevisc_white from '../assets/imgs/ailos/whites/logo_crevisc.svg'
import logo_evolua_white from '../assets/imgs/ailos/whites/logo_evolua.svg'
import logo_transpocred_white from '../assets/imgs/ailos/whites/logo_transpocred.svg'
import logo_unilos_white from '../assets/imgs/ailos/whites/logo_unilos.svg'
import logo_viacredi_white from '../assets/imgs/ailos/whites/logo_viacredi.svg'
import logo_viacrediav_white from '../assets/imgs/ailos/whites/logo_viacrediav.svg'




export default {
  logo,
  logo_white,
  name: "Ailos",
  title: "Ailos Assembleias",
  appcode: 2,
  captchaSiteKey: "6LebO8IhAAAAAApCTVQlIKAmnnP2suTrTsWAuAUk",
  facebookAppId: "261213698471647",
  googleAppId: "820782713227-lp4uepgj2066jnp9e6ca76p59gr1t2a5.apps.googleusercontent.com",
  googleTrackingId: "G-BB6KMK6WHN",
  domainLogos: {
    "localhost:3000": logo_transpocred_white,
    "ACENTRA": logo_acentra_white,
    "ACREDICOOP": logo_acredicoop_white,
    "CIVIA": logo_civia_white,
    "CREDCREA": logo_credcrea_white,
    "CREDELESC": logo_credelesc_white,
    "CREDICOMIN": logo_credicomin_white,
    "CREDIFOZ": logo_credifoz_white,
    "CREVISC": logo_crevisc_white,
    "EVOLUA": logo_evolua_white,
    "TRANSPOCRED": logo_transpocred_white,
    "UNILOS": logo_unilos_white,
    "VIACREDI": logo_viacredi_white,
    "VIACREDI ALTO VALE": logo_viacrediav_white
  },
  showPA:  true,
  showGroup: true,
  allowPublicMessages: true,
  allowPrivateMessages: true,
  enableGuestAccess: true,
  showMyLivestreamsOnly: false,
  showMyLivestreamsByAgencyAndGroup: true,
  prePollTitle: "Assembleias Antecipadas",
  showBallotPaper: true,
  prePollText: "Assembleia Gravada",
  posPollText: "Assembleia Gravada"
};
