import axios from "axios";
import api_url from "./api_url";
import { getToken } from "./token";

const api = axios.create({
  baseURL: api_url,
});

const token = getToken();

if (token) {
  api.defaults.headers.common = {
    Authorization: `Bearer ${token}`
  };
}

export default api;
