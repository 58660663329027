import React from "react";

import Introduction from "../components/Introduction";
import Background from "./components/Background";
import "./styles.scss";

import Form from "./Form";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";
function Uniprime({branch, type}) {
  const logo = useSelector((store) => store.configs.website.logo);

  return (
    <div className="landing-page-uniprime">
      <Introduction type={type}/>
      <Background styles="autoHeight">
        <Form branch={branch} logo={logo} />
      </Background>
      <Background styles="footerSize">
        <Footer logo={logo}/>
      </Background>
    </div>
  );
}

export default Uniprime;
