import React from "react";
import MuxPlayer from "@mux/mux-player-react/dist/index.cjs";

export default function VideoPlayer({
  playbackId,
  prePollTriggerPercentage,
  posPollTriggerPercentage,
  setPrePollReady,
  setPosPollReady,
  pre_poll_enabled,
  pos_poll_enabled,
  socketConnected,
  await_image,
  livestreamOn,
}) {
  function handleVideoChange(event) {
    if (socketConnected) {
      const duration = event.target.duration;
      const currentTime = event.target.currentTime;

      if (duration && currentTime) {
        const percentage = parseInt(currentTime) * 100 / parseInt(duration);
      
        if (
          (percentage >= prePollTriggerPercentage &&
            pre_poll_enabled) ||
          (prePollTriggerPercentage === 0 &&
            pre_poll_enabled)
        ) {
          setPrePollReady(true);
        }
        if (
          (percentage >= posPollTriggerPercentage &&
            pos_poll_enabled) ||
          (posPollTriggerPercentage === 0 &&
            pos_poll_enabled)
        ) {
          setPosPollReady(true);
        }
      }
    }
  }

  function isImage(url) {
    const regexToCheckIfIsAImg = new RegExp(/\.(jpeg|jpg|png|bmp|gif)$/i);

    return regexToCheckIfIsAImg.test(url);
  }

  return !livestreamOn && !pre_poll_enabled && !pos_poll_enabled ? (
    await_image ? (
      isImage(await_image) ? (
        <div className="image-player-container">
          <img className="image-player" src={await_image} />
        </div>
      ) : (
        <MuxPlayer
          streamType="on-demand"
          src={await_image}
          loop={true}
          autoPlay="any"
        />
      )
    ) : (
      <div className="video-player-container" style={{ height: "300px" }}></div>
    )
  ) : (
    <MuxPlayer
      streamType={livestreamOn ? "live" : "on-demand"}
      playbackId={playbackId}
      autoPlay="any"
      onTimeUpdate={handleVideoChange}
    />
  );
}
